import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from 'context';
import { IApprovalData } from './IProxyApproval';
import { getProxyDetails, convertToDate } from 'common';
import { Table } from '@sentaraui/optimahealth_web';

const ViewDesignated = (selectedItem: IApprovalData) => {
  const { setEnableView } = useContext(GlobalContext);
  // Initialize translation
  const { t } = useTranslation();
  const [viewDesignated, setViewDesignated] = useState(selectedItem);
  useEffect(() => {
    setViewDesignated(selectedItem);
  }, [selectedItem]);

  return (
    <>
      <button
        data-testid="backToClaimList"
        className="link-text btn-links arrow-back bg-transparent m-0 border-0 mb-4"
        onClick={() => {
          setEnableView(false);
        }}
      >
        {t('proxy.label.back')}
      </button>

      <h2 className="font-eighteen fw-600 mb-4">
        {t('proxy.label.details')}
      </h2>
      <Table
        className="table-custom-50 mb-4"
        rows={[
          [
            {
              text: (
                <span data-testid="proxyNameLabel" >
                  {t('proxy.label.proxyName')}
                </span>
              ),
            },
            {
              text: (
                <span data-testid="proxyNameValue">
                  {viewDesignated.ProxyFirstName +
                    ' ' +
                    viewDesignated.ProxyLastName}
                </span>
              ),
            },
          ],
          [
            {
              text: (
                <span
                  data-testid="preferredCommunicationLabel"
                >
                  {t('proxy.label.preferredCommunication')}
                </span>
              ),
            },
            {
              text: (
                <span data-testid="preferredCommunicationValue">
                  {viewDesignated.ProxyPreferredCommunicationMethod ===
                  getProxyDetails.email
                    ? t('proxy.label.email')
                    : t('proxy.label.mobile')}
                </span>
              ),
            },
          ],
          [
            {
              text: (
                <span data-testid="currentStatusLabel">
                  {t('proxy.label.currentStatus')}
                </span>
              ),
            },
            {
              text: (
                <span data-testid="currentStatusValue">
                  {t('proxy.label.active')}
                </span>
              ),
            },
          ],
        ]}
      />
      <h2 className="font-eighteen fw-600 mb-4">
        {t('proxy.label.logs')}
      </h2>
      <Table
        className="table-custom-50 mb-4"
        header={[
          {
            text: (
              <div data-testid="proxyLogStatus">{t('proxy.label.status')}</div>
            ),
          },
          {
            text: <div data-testid="proxyLogDate">{t('proxy.label.date')}</div>,
          },
        ]}
        rows={[
          [
            {
              text: (
                <span data-testid="initiatedLabel">
                  {t('proxy.label.initiated')}
                </span>
              ),
            },
            {
              text: (
                <span data-testid="initiatedLabelValue">
                  {convertToDate(viewDesignated?.StatusHistory[0]?.Date)}
                </span>
              ),
            },
          ],
          [
            {
              text: (
                <span data-testid="approvedLabel">
                  {t('proxy.label.approved')}
                </span>
              ),
            },
            {
              text: (
                <span data-testid="approvedValue">
                  {convertToDate(viewDesignated?.StatusHistory[1]?.Date)}
                </span>
              ),
            },
          ],
        ]}
      />
    </>
  );
};

export default ViewDesignated;
