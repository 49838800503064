import { useState, useRef, useContext } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { t } from 'i18next';
import {
  UserDetails,
  setLocalStorageBoolean,
  signIn,
  SignOutHandler,
  deleteEncryptedValue,
} from 'common';


import {
  Button,
  Headings,
  ModalOverlayWithoutClose,
} from '@sentaraui/optimahealth_web';
import { GlobalContext } from 'context';
import { useRefreshToken } from '@sentara/sentara-api-hooks-core';

function IdleTimeoutContainer() {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const { getFeature } = useContext(GlobalContext);
  const sessionTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const signOut = SignOutHandler();
  const { refreshToken } = useRefreshToken();

  // Initialize continue session handler function
  const handleContinueSession = async () => {
    setModalIsOpen(false);
    const ciamEnabled = getFeature?.isModernizedAPI;

    if (ciamEnabled) {
      try {
        const response = await refreshToken();
        if (response?.data?.latestAccessTime ===  null && response?.data?.maxIdleExpirationTime ===  null
            && response?.data?.maxSessionExpirationTime === null) {
          // Handle other status codes or errors
          alert();
        }
      }  catch(error) {
        alert();
      }
    }

    if (sessionTimeoutRef.current !== null) {
      clearTimeout(sessionTimeoutRef.current);
    }
  };

  const onIdle  = () => {
    setModalIsOpen(true);
    sessionTimeoutRef.current = setTimeout(alert, 60000);
  };

  const alert = () => {
    localStorage.clear();
    deleteEncryptedValue();
    window.location.href = signIn;
    setModalIsOpen(false);
    if (sessionTimeoutRef.current !== null) {
      clearTimeout(sessionTimeoutRef.current);
    }
    setLocalStorageBoolean(UserDetails?.inActiveSignOut, true);
  };

  useIdleTimer({
    onIdle,
    timeout: (getFeature?.isModernizedAPI ? 600 : 840) * 1000,
    name: 'idle-timer',
  });

  return (
    <div>
      <ModalOverlayWithoutClose
        isOpen={modalIsOpen}
        overlayInfo="Modal information"
      >
        <div data-testid="timeOut-title">
          <Headings
            level={4}
            text={t('session.label.modalTitle')}
            variant="bold"
            className="mb-3 me-4"
          />
          <p data-testid="sessionModalDescription">
            {t('session.label.modalDescription')}
          </p>
          <div className="row pt-2 pb-2">
            <div className="col-lg-auto col-md-auto col-sm12 col-12 d-grid gap-2 d-md-block mb-4">
              <Button
                dataTestId={'modalOkButton'}
                variant={'primary_btn btn_block_mob'}
                type="submit"
                onClick={handleContinueSession}
              >{t('session.label.modalOk')}</Button>
            </div>
            <div className="col-lg-auto col-md-auto col-sm12 col-12 d-grid gap-2 d-md-block mb-4">
              <Button
                dataTestId={'cancelButton'}
                variant={'primary_btn btn_block_mob'}
                onClick={signOut}
              >{t('session.label.modalCancel')}</Button>
            </div>
          </div>
        </div>
      </ModalOverlayWithoutClose>
    </div>
  );
}

export default IdleTimeoutContainer;
