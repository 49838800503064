import React, { useState, useContext } from 'react';
import { t } from 'i18next';
import {
  Button,
  ModalOverlay,
  GlobalError,
  Headings,
  Paragraph,
  Loader,
} from '@sentaraui/optimahealth_web';
import { useForm, FormProvider } from 'react-hook-form';
import { UpdateSecurityQuestions } from 'templates';
import { useSaveSecurityQuestions } from '@sentara/sentara-api-hooks-core';
import { GlobalContext } from 'context';
import { ProfileInformations, SecurityQuestion, SecurityQuestionProps } from './interface';
import { errorhandler } from 'common';
const SecurityAnswer = ({
  securityQuestion,
  questionAnswer,
  setQuestionAnswer,
  state,
  setState,
}: {
  securityQuestion: SecurityQuestionProps[];
  questionAnswer: SecurityQuestion[];
  setQuestionAnswer: React.Dispatch<React.SetStateAction<SecurityQuestion[]>>;
  state: ProfileInformations;
  setState: React.Dispatch<React.SetStateAction<ProfileInformations>>;
}) => {
  const [showEdit, setShowEdit] = useState(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const { saveSecurityQuestions } = useSaveSecurityQuestions();
  const { getFeature, proxyMemberId } = useContext(GlobalContext);
  const methods = useForm({
    
  });
  const {
    handleSubmit,
    reset,
    resetField,
    formState: { errors, isValid },
  } = methods;

  const Ids = [
    { id: 'Q1', testId: 'One' },
    { id: 'Q2', testId: 'Two' },
    { id: 'Q3', testId: 'Three' },
  ];

  function closeModal() {
    setIsOpen(false);
    setState({ ...state, securityQuestions: questionAnswer });
    for (let i = 0; i < 3; i++) {
      resetField(`questionAnswer${i}`);
    }
    setShowEdit(!showEdit);
    
  }

  const onHandleChange = (index: any, value: any, key: any) => {
    const updatedAnswer = [...questionAnswer];
    updatedAnswer[index] = { ...updatedAnswer[index], [key]: value };
    setQuestionAnswer(updatedAnswer);
  };

  const onSubmit = async () => {
    setLoader(true);  
    let address;
    const data  = await saveSecurityQuestions(
      proxyMemberId,
      getFeature?.AccountSettings,
      address,
      questionAnswer
    );   
    if (data.errorCode) {
      errorhandler(data?.errorCode);
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }   
    setLoader(false);
  };
  return (
    <div className="row">
      {loader && <Loader />}
      <div className="">
        <Headings
          level={6}
          text={t('activation.label.securityQts')}
          variant="semiBold"
          dataTestId={'securityQts'}
          className={`font-sixteen mb-3`}
        />
      </div>
      {/* <div className="d-flex"> */}
      {showEdit ? (
        <div className="mb-3 col-md-8 col-lg-8">
          <div className="d-flex flex-column gap-2 row justify-content-between">
            {questionAnswer?.map((item: { question: string; questionAnswer: string }) => (
           
                  <div className="d-flex justify-content-between col mb-2">
                    <Paragraph className="mb-0">{item.question}</Paragraph>
                    <Paragraph  className="mb-0">{'***********'}</Paragraph>
                  </div>
            ))}
          </div>
        </div>
      ) : null}
      {showEdit ? (
        <div className="mb-3 col-md-4 button_width">
          <Button
            dataTestId={'Edit'}
            variant={'secondary_btn btn_block_mob mt-3 mt-lg-0 mt-md-0'}
            onClick={() => setShowEdit(!showEdit)}
          >{t('edit')}</Button>
        </div>
      ) : null}
      {!showEdit ? (
        <div className="pt-2">
          {(errors?.questionAnswer0 ||
            errors?.questionAnswer1 ||
            errors?.questionAnswer2) && (
            <GlobalError variant="mb-3" dataTestId="securityAnsError">
              {t('settings.errors.securityAnsError')}
            </GlobalError>
          )}
          <FormProvider {...methods}>
            <form
              data-testid="submit_form"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <UpdateSecurityQuestions
                securityQuestion={securityQuestion}
                hasStyle={false}
                placeholder
                {...{
                  questionAnswer,
                  setQuestionAnswer,
                  Ids,
                  onHandleChange,
                }}
              />

              <hr className='hr_line'/>
              <div className="pt-2 pb-2 button_container">
                  <Button
                    dataTestId={'submitButton'}
                    variant={
                      isValid
                        ? 'primary_btn btn_block_mob'
                        : ' primary_btn  btn_block_mob disabled_btn'
                    }
                    type="submit"
                  >
                    {t('settings.label.save')}
                  </Button>
                  <Button
                    dataTestId={'cancelButton'}
                    variant={'secondary_btn btn_block_mob'}
                    onClick={() => {
                      setShowEdit(!showEdit);
                      setQuestionAnswer(state.securityQuestions as unknown as SecurityQuestion[]);
                      reset();
                    }}
                  >
                    {t('settings.label.cancel')}
                  </Button>
                {isOpen && (
                  <ModalOverlay
                    isOpen
                    onClose={closeModal}
                    className="modal_overlay"
                    overlayInfo="Modal information"
                  >
                    <div data-testid="memberIdModal">
                      <Paragraph
                        className="mb-0"
                        data-testid="security-description"
                        >
                        {t('settings.message.securityQuestionUpdate')}
                        </Paragraph>
                      <div className="d-flex flex-column flex-lg-row flex-md-row  align-items-center justify-content-center mt-3 gap-3">
                        <Button
                          dataTestId={'security_ok'}
                          variant={'primary_btn btn_block_mob'}
                          onClick={closeModal}
                        >
                          {t('settings.label.ok')}
                        </Button>
                      </div>
                    </div>
                  </ModalOverlay>
                )}
              </div>
            </form>
          </FormProvider>
        </div>
      ) : null}
      {/* </div> */}
    </div>
  );
};

export default SecurityAnswer;
