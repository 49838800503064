import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { EyeIconClose, EyeIconOpen, getMaxLength,ModalCustomStyle,HelpIcon } from 'common';
import {
  Button,
  Label,
  ErrorMessage,
  InputField,
} from '@sentaraui/optimahealth_web';
import PasswordInfoOverlay from '../pages/forms/PasswordInfoOverlay';
import Modal from 'react-modal';

// Define the interface for the props passed to ConfirmPasswordInput component
export interface PasswordChangeProps {
  // Handler for onKeyDown event
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  showPasswordPlaceholder?: string;
  showIcon?: boolean;
  className?: string;
}

// Define the ConfirmPasswordInput component
const ConfirmPassword: React.FC<PasswordChangeProps> = ({
  onKeyDown,
  showPasswordPlaceholder,
  showIcon,
  className
}) => {
  const { t } = useTranslation();

  // State to manage password visibility
  const [confirmPasswordOpen, setConfirmPasswordOpen] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const methods = useFormContext();
  const {
    getValues,
    formState: { errors },
  } = methods;

  function openModal(e: { preventDefault: () => void }) {
    e.preventDefault();
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      {/* Container for the component */}
      <div className={className ? className : "mb-3 pos-rel"}>
        {/* Label for the input */}
        <Label
          dataTestId={'confirmPasswordInputLabel'}
          htmlFor="password"
          className="form-label"
          children={t('activation.label.confirmPassword')}
        />
        {/* Display asterisk if password length is less than 2 */}
        <span className="text-danger px-1">*</span>
        {/* Container for input and icon */}
        <div className="d-flex align-items-center singin-input pos-rel">
          <div className="col">
            <div className='d-flex align-items-center pe-0 pe-lg-2 pe-md-2'>
            <div className="pos-rel flex-1">
              {/* Input field */}
              <InputField
                className={
                  errors?.confirmPassword
                    ? 'error-border form-input psw-input'
                    : 'form-input psw-input'
                }
                autoComplete="new-password"
                maxLength={getMaxLength.password}
                type={!confirmPasswordOpen ? 'password' : 'text'}
                dataTestId="confirmPasswordInput"
                placeholder={showPasswordPlaceholder ?? '********'}
                onKeyDown={onKeyDown}
                {...methods.register('confirmPassword', {
                  required: true,
                  validate: (value) => {
                    return (
                      value === getValues('password') ||
                      'Password does not match'
                    );
                  },
                })}
              />
              {/* Button to toggle password visibility */}
              <Button
                dataTestId="confirmPasswordInputEyeIcon"
                className="eye_icon"
                onClick={(e: { preventDefault: () => void }) => {
                  e.preventDefault();
                  setConfirmPasswordOpen(!confirmPasswordOpen);
                }}
              >
                {/* Toggle eye icon based on password visibility */}
                {!confirmPasswordOpen ? (
                  <span
                    className="visually-hiiden"
                    aria-label="Eye icon button click to show the password"
                  >
                    <EyeIconClose />
                  </span>
                ) : (
                  <span
                    className="visually-hiiden"
                    aria-label="Eye icon button click to hide the password"
                  >
                    <EyeIconOpen />
                  </span>
                )}
              </Button>
            </div>
            {showIcon ? (
                <div className="ms-2">
                  <Button
                    data-testid="memIdHelpInfo"
                    onClick={openModal}
                    aria-label="Information icon click to view"
                    variant="border-0 bg-transparent pe-0"
                    type={'button'}
                  >
                    <HelpIcon />
                  </Button>
                </div>
              ) : null}
              </div>
                 <Modal
              isOpen={isOpen}
              onRequestClose={closeModal}
              data-testid="userNameInputModal"
              contentLabel="Username Information Overlay"
              style={ModalCustomStyle}
            >
              <div
                className="w-member-overlay"
                data-testid="userNameInputModalModal"
              >
                <div className="text-right">
                  <Button
                    type="button"
                    data-testid="userNameInputModalClose"
                    className="btn-close"
                    onClick={closeModal}
                    aria-label="Close"
                  >
                    <span aria-hidden="true" className="visually-hidden">
                      {t('appointments.buttonLableTwoClose')}
                    </span>
                  </Button>
                </div>
                <PasswordInfoOverlay />
              </div>
            </Modal>
            {/* Display error messages */}
            {errors?.confirmPassword?.type === 'required' && (
              <div className="mt-1">
                <ErrorMessage
                  dataTestId={'confirmPasswordRequiredError'}
                  children={
                    window.location.pathname.includes('activation')
                      ? t('settings.errors.confirmPwd')
                      : t('activation.errors.confirmPwd')
                  }
                />
              </div>
            )}
            {errors?.confirmPassword?.type === 'validate' && (
              <div className="mt-1">
                <ErrorMessage
                  dataTestId={'confirmPasswordRequiredError'}
                  children={t('settings.errors.confirmPasswordrequired')}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmPassword;
