import { useTranslation } from 'react-i18next';
import {
    Headings,
    Paragraph
} from '@sentaraui/optimahealth_web';
import WellnessImage_1 from '../../assets/images/Img_Wellness_1.png';
import WellnessImage_2 from '../../assets/images/Img_Wellness_2.png';
import WellnessImage_3 from '../../assets/images/Img_Wellness_3.png';
import WellnessImage_4 from '../../assets/images/Img_Wellness_4.png';
import WellnessImage_5 from '../../assets/images/Img_Wellness_5.png';
import { WEBMD_WELLNESS_URLS } from 'common';
import WellnessProgramItem from 'templates/WellnessProgramItem';

const WellnessPrograms = () => {

    const { t } = useTranslation();

    //static data for wellness programs
    const wellnessPrograms = [
        {
            id: 1,
            img: WellnessImage_1,
            title: t('guestWebMD.wellnessPageStaticData.wellnessTools.title'),
            description: t('guestWebMD.wellnessPageStaticData.wellnessTools.description'),
            link: WEBMD_WELLNESS_URLS.wellnessToolsURL,
            linkText: t('guestWebMD.wellnessPageStaticData.wellnessTools.linkText'),
        },
        {
            id: 2,
            img: WellnessImage_2,
            title: t('guestWebMD.wellnessPageStaticData.selfPacedPrograms.title'),
            description: t('guestWebMD.wellnessPageStaticData.selfPacedPrograms.description'),
            link: WEBMD_WELLNESS_URLS.selfPacedProgramsURL,
            linkText: t('guestWebMD.wellnessPageStaticData.selfPacedPrograms.linkText'),
        },
        {
            id: 3,
            img: WellnessImage_3,
            title: t('guestWebMD.wellnessPageStaticData.healthyOnDemand.title'),
            description: t('guestWebMD.wellnessPageStaticData.healthyOnDemand.description'),
            link: WEBMD_WELLNESS_URLS.healthyOnDemandURL,
            linkText: t('guestWebMD.wellnessPageStaticData.healthyOnDemand.linkText'),
        },
        {
            id: 4,
            img: WellnessImage_4,
            title: t('guestWebMD.wellnessPageStaticData.exclusiveDiscounts.title'),
            description: t('guestWebMD.wellnessPageStaticData.exclusiveDiscounts.description'),
            link: WEBMD_WELLNESS_URLS.exclusiveDiscountsURL,
            linkText: t('guestWebMD.wellnessPageStaticData.exclusiveDiscounts.linkText'),
        },
        {
            id: 5,
            img: WellnessImage_5,
            title: t('guestWebMD.wellnessPageStaticData.diabetesPrevention.title'),
            description: t('guestWebMD.wellnessPageStaticData.diabetesPrevention.description'),
            link: WEBMD_WELLNESS_URLS.diabetesPreventionURL,
            linkText: t('guestWebMD.wellnessPageStaticData.diabetesPrevention.linkText'),
        },
    ];

    return (
        <div className="enroll_in_benefits black-color">
            <div className="mb-4">
                <Headings
                    level={1}
                    text={t('guestWebMD.wellness.title')}
                    variant="semiBold"
                />
            </div>
            <div>
                <Paragraph>{t('guestWebMD.wellness.content')} </Paragraph>
            </div>

            <div className="d-flex flex-column gap-5 pt-3 pb-4">
                {wellnessPrograms.map((program,index) => (
                    <WellnessProgramItem key={program.img} program={program} index={program.id}/>
                ))}
            </div>
        </div>
    );
};

export default WellnessPrograms;