import React from 'react';
import { Button, Headings, Paragraph } from '@sentaraui/optimahealth_web';
import { t } from 'i18next';
import { HeaderContentProps } from 'templates';

function ProfileHeaderContent({
  header,
  contentOne,
  contentTwo,
  className,
  onClick,
  dataTestIdOne,
  dataTestIdTwo,
  contentClassNameOne,
  contentClassNameTwo,
  showButton = false,
  fieldName = '',
  disableButton = false,
}: HeaderContentProps) {
  return (
    <>
      <div className="row align-items-center justify-content-end pb-2">
        <div
          className={
            !showButton
              ? 'col-md-12 col-12 col-lg-12'
              :  `col-12 mb-2 mb-md-0 mb-lg-0 ${fieldName === t('settings.label.homeAddress') ? "col-md-7 col-lg-7" : 'col-md-8 col-lg-8'}`
          }
        >
          <Headings
            className={`${className} font-sixteen`}
            level={6}
            text={header}
            variant="bold"
            dataTestId={"header"}
          />
          <Paragraph
            className={contentClassNameOne}
            data-testid={dataTestIdOne}
            children={contentOne}
          />
          {contentTwo && (
            <Paragraph
              className={contentClassNameTwo}
              data-testid={dataTestIdTwo}
              children={contentTwo}
            />
          )}
        </div>
        {showButton && (
            <div className={`col-12 mb-2 text-end ${fieldName === t('settings.label.homeAddress') ? "pe-5 col-lg-5 col-md-5 ":'col-lg-4 col-md-4'}`}>
              <Button
                dataTestId={t('edit') ? t('edit') : 'editButton'}
                children={t('edit')}
                variant={'secondary_btn btn_block_mob  mt-lg-0 mt-md-0'}
                onClick={onClick}
                disabled={disableButton}
              />
            </div>
        )}
      </div>
    </>
  );
}

export default ProfileHeaderContent;
