import {
  Headings,
  Button,
  Image,
  Paragraph,
  Label,
  VirtualIcon,
} from '@sentaraui/optimahealth_web';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import physicalTherapy from '../../assets/images/physicalTherapy.svg';
import {
  navigateToExternalUrl,
  virtualVisitUrl,
  PHYSICAL_THERAPY_URL,
} from 'common';

function PhysicalTherapy() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      navigate(virtualVisitUrl);
    }
  };

  return (
    <div>
      <div
        data-testid="back"
        className="d-flex flex-row align-items-center cursor-pointer"
        onClick={() => navigate(virtualVisitUrl)}
        role="button"
        tabIndex={0}
        onKeyDown={handleKeyDown}
        aria-label={t('physicalTherapy.label.backButton') || 'Back'}
      >
        <span className="arrow-back-black" />
        <Headings
          level={1}
          text={t('physicalTherapy.label.title')}
          variant="semiBold"
          dataTestId="physicalTherapyHeader"
        />
      </div>
      <div className="row py-4">
        <div className="col-md-6">
          <Image
            dataTestId="physicalTherapyImage"
            className="rounded-4 w-100"
            path={physicalTherapy}
          />
        </div>
        <div className="d-flex flex-column col-md-6 mt-3 mt-md-0">
          <Headings
            level={2}
            text={t('physicalTherapy.label.physicalTherapy')}
            variant="semiBold"
            dataTestId="physicalTherapyHeader"
          />
          <span className="d-inline-block my-2">{<VirtualIcon />}</span>
          <Paragraph
            className="font-size-16 mt-2"
            dataTestId="physicalTherapyContent"
          >
            {t('physicalTherapy.content.physicalTherapyContent')}
          </Paragraph>
          <div className="d-flex align-items-center mt-1">
            <Button
              className="btn virtual-visit-button-style"
              data-testid="schedulePhysicalTherapyAppointment"
              variant="primary_btn"
              onClick={() => {
                navigateToExternalUrl(PHYSICAL_THERAPY_URL);
              }}
            >
              {t('virtualVisitCare.selectProviderPopup.scheduleAppointment')}
            </Button>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column my-2">
        <Headings
          level={5}
          text={t('physicalTherapy.label.rehabTherapy')}
          variant="semiBold"
          dataTestId="physicalRehabTherapyHeader"
        />
        <ul className="custom-list">
          <li>
            <Label>{t('physicalTherapy.label.skilledProviders')}</Label>
            <span className="font-size-16 ms-1">
              {t('physicalTherapy.content.skillProviderContent')}
            </span>
          </li>
          <li>
            <Label>{t('physicalTherapy.label.collaborativeCare')}</Label>
            <span className="font-size-16 ms-1">
              {t('physicalTherapy.content.collaborativeCareContent')}
            </span>
          </li>
          <li>
            <Label>
              {t('physicalTherapy.label.convenientTherapySessions')}
            </Label>
            <span className="font-size-16 ms-1">
              {t('physicalTherapy.content.convenientTherapySessionsContent')}
            </span>
          </li>
        </ul>
        <span className="font-size-16 ms-1 mt-5 italic-style">
          {t('physicalTherapy.content.physicalTherapyDisclaimerContent')}
        </span>
      </div>
    </div>
  );
}

export default PhysicalTherapy;
