import { useState, useEffect, useContext } from 'react';
import { usePreferenceDetails } from '@sentara/sentara-api-hooks-core';
import { HomeAddressDetails, EmailPaperless, ProfileHeaderContent } from 'templates';
import { GlobalContext } from 'context';
import { useTranslation } from 'react-i18next';
import {
  Address,
  userAddressTypeHome,
  Phone,
  getDataByAddressType,
  ApiWrapper,
  formatePhoneNo
} from 'common';
import { Headings, Loader } from '@sentaraui/optimahealth_web';

const MemberProfile = () => {
  //Initialize translation hook
  const { t } = useTranslation();
  const { preferenceDetails } = usePreferenceDetails();
  const [userAddress, setUserAddress] = useState<Address[]>([]);
  const [userPhone, setUserPhone] = useState<Phone[]>([]);
  const { getFeature, loginMemberId, proxyMemberId } =
    useContext(GlobalContext);
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState<any>({});
  const {
    data: profileResponse,
    apiError: profileError,
    callApi,
  } = ApiWrapper(preferenceDetails);
const [showEdit, setShowEdit] = useState(true);
  useEffect(() => {
    if (getFeature?.AccountSettings !== undefined) {
      let data = {
        memberId: btoa(loginMemberId),
      };
      setLoader(true);
      callApi(data, proxyMemberId, getFeature?.AccountSettings);
    }
  }, [loginMemberId, getFeature?.AccountSettings]);

  useEffect(() => {
    if (profileError) {
      setLoader(false);
    } else if (profileResponse) {
      setState(profileResponse);
      setLoader(false);
    }
  }, [profileResponse, profileError]);

  

  useEffect(() => {
    (async () => {
      getDataByAddressType(
        state && state?.address,
        userAddressTypeHome,
        setUserAddress
      );
      getDataByAddressType(
        state && state?.phones,
        userAddressTypeHome,
        setUserPhone
      );
    })();
  }, [state && state?.dependents]);

  return (
    <>
      {loader && <Loader />}
      <div className="enroll_in_benefits black-color">
        <div className="mb-4">
          <Headings
            level={1}
            text={t('memberProfile.label.title')}
            variant="semiBold"
            data-testid="enrollBenefitsHeader"
          />
        </div>

        {/* To display the Home Address */}
        <div className="border border-clr border_radius_18 p-4 mb-4">
          <div className="mb-2 mb-md-3 mb-lg-3 set_my_preferences">
              <div className="row align-items-center justify-content-end">
                <div>
                  {showEdit ? (
                    <>
                      <ProfileHeaderContent
                        header={t('settings.label.homeAddress')}
                        contentOne={userAddress[0]?.street ?? ''}
                        contentTwo={` ${userAddress[0]?.city ? `${userAddress[0]?.city}, ` : ''}
                        ${userAddress[0]?.state ?? ''}
                        ${userAddress[0]?.zipCode ?? ''}`}
                        contentClassNameTwo={'margin_top_6 mb-0'}
                        contentClassNameOne={'mb-0'}
                        dataTestIdOne="userAddressStreet"
                        dataTestIdTwo="userAddress"
                        showButton={state?.canChangeContactInfo}
                        fieldName={t('settings.label.homeAddress') || ''}
                        onClick={() => setShowEdit(!showEdit)}
                      />
                    </>
                  ) : (
                    <HomeAddressDetails
                      canChangeContactInfo={
                        state?.canChangeContactInfo || false
                      }
                      userAddress={userAddress}
                      city={userAddress[0]?.city ?? ''}
                      dependents={state?.dependents || []} // Update the type of dependents prop
                      street={userAddress[0]?.street ?? ''}
                      zipCode={userAddress[0]?.zipCode ?? ''}
                      state={userAddress[0]?.state ?? ''}
                      closeComponent={() => setShowEdit(!showEdit)}
                      setUserAddress={setUserAddress}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* To display the phone number */}
            <div className="mb-3 pt-3">
              <ProfileHeaderContent
                header={t('primaryPhoneNumber')}
                contentOne={formatePhoneNo(userPhone[0]?.phoneNumber ?? '')}
                dataTestIdOne="profileName"
                contentClassNameOne={'_disabled mb-2'}
              />
            </div>
            
            {/* To display the Email Address */}
            <EmailPaperless
              state={state}
              setState={setState}
              memberView={true}
            />
        </div>
      </div>
    </>
  );
};

export default MemberProfile;
