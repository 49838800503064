import { useTranslation } from 'react-i18next';
import image from '../../../assets/images/memberidcard_img.png';
import avmedIdImg from '../../../assets/images/avmedmemberid_img.png';
import { getMaxLength, userLockedOut } from 'common';
import { isAvMed } from 'context';
import { getCaptchaSiteKey } from 'common/utils/constants';



export const ForgotUserNameHelper = () => {
  const { t } = useTranslation();
  let memberIDImg = isAvMed() ? avmedIdImg : image;
  const planTypes = {
    medicaid: {
      planId: '1234',
      planName: t('activation.label.getmedicaId'),
      title: t('activation.label.getmedicaId'),
      value: '1234',
    },
    medicare: {
      planId: '2345',
      planName: t('activation.label.getmedicareId'),
      title: t('activation.label.getmedicareId'),
      value: '2345',
    },
    employer: {
      planId: '3456',
      planName: t('activation.label.individualEmployer'),
      title: t('activation.label.individualEmployer'),
      value: '3456',
    },
    guestEnrollee: {
      planId: '4567',
      planName: t('activation.label.guestEnrollee'),
      title: t('activation.label.guestEnrollee'),
      value: '4567',
    },
  };

  const emailData = {
    emptyErrorMessage: t('activation.errors.emailId'),
    label: t('yourPlan.label.emaillbl'),
    maxLength: getMaxLength.email,
    placeHolder: t('yourPlan.label.emaillblPlaceholder'),
    validErrorMessage: t('activation.errors.emailId'),
  };

  const ssnData = {
    emptyErrorMessage: t('activation.errors.ssn'),
    label: t('activation.label.ssnNew'),
    maxLength: getMaxLength.ssn,
    placeHolder: t('activation.placeholder.ssn'),
  };

  const dobData = {
    emptyErrorMessage: t('activation.errors.dob'),
    label: t('activation.label.dateOfBirth'),
    placeHolder: t('activation.placeholder.dateOfBirth'),
    validErrorMessage: t('activation.errors.dateofbirthFormat'),
  };

  return {
    labelHeader: t('requestUserName'),
    memberId: {
      emptyErrorMessage: t('memberIdRequired'),
      label: t('activation.label.memberIdlabel'),
      labelHeader: t('haveMemberId'),
      maxLength: getMaxLength.memberId,
      placeHolder: t('forgetUser.label.enterMemberID'),
    },
    memberIdToolTip: {
      memberIDCardToolTipHeader: t(
        'activation.messages.memberIDCardToolTipHeader'
      ),
      memberIDCardToolTipText1: t(
        'activation.messages.memberIDCardToolTipText1'
      ),
      memberIDCardToolTipText2: t(
        'activation.messages.memberIDCardToolTipText4'
      ),
      memberIDCardToolTipImage: memberIDImg,
    },
    labelOrText: t('activation.label.or'),
    errorMessageFromAPI: t('requestUserId.message.serviceError'),
    planType: {
      labelPlanTypeHeader: t('labelPlanTypeHeader'),
      labelPlanTypeBasicInfo: t('labelPlanTypeBasicInfo'),
      label: t('activation.label.plantype'),
      placeHolder: t('activation.label.selectPlanType'),
      planTypeDropDown: planTypes,
      emptyErrorMessage: t('activation.errors.planType'),
      medicare: {
        emptyErrorMessage: t('activation.errors.medicareId'),
        label: t('activation.label.medicareId'),
        maxLength: getMaxLength.medicare,
        placeHolder: t('activation.placeholder.medicareId'),
      },
      medicaid: {
        emptyErrorMessage: t('activation.errors.medicaId'),
        label: t('activation.label.medicaId'),
        maxLength: getMaxLength.medicare,
        placeHolder: t('activation.placeholder.medicaId'),
      },
      guestEnrollee: {
        email: emailData,
        ssn: ssnData,
        dob: dobData,
      },
      employerGroup: {
        radio: {
          labelRadioBtnHeader: t('labelRadioBtnHeader'),
          labelRadioMemberId: t('activation.label.memberIdlabel'),
          labelRadioPersonalInfo: t('activation.label.personalInfo'),
        },
        memberId: {
          emptyErrorMessage: t('memberIdRequired'),
          label: t('activation.label.memberIdlabel'),
          maxLength: getMaxLength.memberId,
          placeHolder: t('forgetUser.label.enterMemberID'),
        },
        memberIdToolTip: {
          memberIDCardToolTipHeader: t(
            'activation.messages.memberIDCardToolTipHeader'
          ),
          memberIDCardToolTipText1: t(
            'activation.messages.memberIDCardToolTipText1'
          ),
          memberIDCardToolTipText2: t(
            'activation.messages.memberIDCardToolTipText4'
          ),
          memberIDCardToolTipImage: memberIDImg,
        },
        email: emailData,
        ssn: ssnData,
        dob: dobData,
      },
    },
    planTypeModal: {
      dontKnowLinkText: t('dontKnowLinkText'),
      planTypeModalHeaderText: t('planTypeModalHeaderText'),
      planTypeModalText1: t('activation.messages.callMember1'),
      planTypeModalText2: t('activation.messages.callMember2'),
      planTypeModalText3: t('activation.messages.callMember3'),
    },
    captcha: {
      captchaErrors: t('activation.messages.captchaError'),
      siteKey: getCaptchaSiteKey() ?? '',  
    },
    submitCancel: {
      cancelNavigationURL: '/',
      labelSubmit: t('activation.label.continue'),
      labelCancel: t('activation.label.cancel'),
    },
    securityQuestion: {
      accountLockErrorMessage: t('activation.errors.accountLockoutMessage'),
      apiErrorDetails: userLockedOut,
      emptyErrorMessage: t('requestUserId.message.errorMessage'),
      maxLength: getMaxLength.securityQuestion,
      placeHolder: t('activation.label.answerPlaceholderHere'),
      securityQuestionSubmitButtonText: t('activation.label.submit'),
      securityQuestionCancelButtonText: t('activation.label.cancel'),
      securityQuestionCancelButtonURL: '/',
      errorMessageSecurityQuestions: t('requestUserId.message.serviceError'),
    },
    successModal: {
      returnToSignInButtonText: t('forgotPassword.label.returnLogin'),
      returnToSignInURL: '/',
      successMessage: t('activation.label.successMessage'),
      usernameLabelText: t('requestUserId.message.yourUsername'),
    },
    apiErrors: {
      errorMessage204FromAPI: t('requestUserId.message.serviceError'),
    },
    logForgotUsername: {
      screenName: 'Forgot Username',
      planType: {
        additionalDetails: 'log is coming from the forgot username plan type',
        apiException: 'Plan Type Error',
        message: 'Plan Type is selected',
      },
      memberID: {
        submit: {
          additionalDetails:
            'log is coming from the member id screen - submit button',
          apiException: 'Invalid data, try again',
          message: 'Submit button - Member ID screen',
        },
        cancel: {
          additionalDetails:
            'log is coming from the member id screen - cancel button',
          apiException: 'Invalid data, try again',
          message: 'Landing screen - Redirect to sign in',
        },
      },
      personalInfo: {
        additionalDetails: 'log is coming from the personal info screen',
        apiException: 'Invalid data, try again',
        message: 'Personal Info is entered',
      },
      securityQuestionsScreenName: 'Forgot Username Security Questions',
      securityQuestions: {
        submit: {
          additionalDetails: 'log is coming from the security questions screen',
          apiException:
            'Please confirm that the answer you entered is accurate.',
          message: 'Submit button - Security Questions screen',
        },
        cancel: {
          additionalDetails: 'log is coming from the security questions screen',
          apiException:
            'Please confirm that the answer you entered is accurate.',
          message: 'Cancel button - Security Questions screen',
        },
      },
      successScreenName: 'Forgot Username Success screen',
      success: {
        additionalDetails: 'log is coming from the success screen',
        apiException: 'Success Screen Error',
        message: 'Success screen - Redirect to Sign In',
      },
    },
  };
};
