import React from 'react';
import { useTranslation } from 'react-i18next';

function UsernameInfoOverlay() {
  const { t } = useTranslation();
  return (
    <div className="memoverlay-content">
      <h5
        className="memoverlay-title mb-2"
        data-testid="userNameInfoOverlayHeading"
      >
        {t('activation.messages.userNameTooltipText')}
      </h5>
      <ul className="mb-2">
        <li data-testid="userNameInfoOverlayTextOne">
          {t('activation.messages.userNameRequirementsOne')}
        </li>
        <li data-testid="userNameInfoOverlayTextTwo">
          {t('activation.messages.userNameRequirementsTwo')}
        </li>
        <li data-testid="userNameInfoOverlayTextThree">
          {t('activation.messages.userNameRequirementsThree')}
        </li>
        <li data-testid="userNameInfoOverlayTextFour">
          {t('activation.messages.userNameRequirementsFour')}
        </li>
        <li data-testid="userNameInfoOverlayTextFive">
          {t('activation.messages.userNameRequirementsFive')}
        </li>
      </ul>
      <p className="mb-2" data-testid="userNameInfoOverlayTextSix">
        {t('activation.messages.userNameRequirementsSix')}
      </p>
    </div>
  );
}

export default UsernameInfoOverlay;
