import React from 'react';
import { ModalOverlay, Headings, Label, InputField, ErrorMessage, Button } from '@sentaraui/optimahealth_web';// replace with your actual imports
import {  
  NumberFormat
} from 'common';

interface ConfirmIdentityModalProps {
  isConfirmIdentityOpen: boolean;
  closePopUpConfirmModal: () => void;
  getValues: (field: string) => string | undefined;
  verificationCode: string;
  setVerificationCode: (code: string) => void;
  submitClicked: boolean;
  inValidOPTError: boolean;
  setInValidOPTError: (error: boolean) => void;
  setSubmitClicked: (submit: boolean) => void;
  onSubmit: () => void;
  handleOtpSubmit: () => void;
  t: (key: string) => string; // replace with your actual i18n function type
  smsInputMaxLength: number;
}

  const ConfirmIdentityModal = ({
    isConfirmIdentityOpen,
  closePopUpConfirmModal,
  getValues,
  verificationCode,
  setVerificationCode,
  submitClicked,
  inValidOPTError,
  setInValidOPTError,
  onSubmit,
  handleOtpSubmit,
  setSubmitClicked,
  t,
  smsInputMaxLength,
}: ConfirmIdentityModalProps) => {
  

  return (
       <ModalOverlay
            isOpen={isConfirmIdentityOpen}
            onClose={closePopUpConfirmModal}
            overlayInfo="Go paperless overlay"
            className="confirm_identity_modal"
          >
            <Headings
              level={2}
              text={t('settings.label.confirmYourIdentity')}
              variant="regular"
              dataTestId="emailNotificationsAndConsentTitle"
            />
            <div
              data-testid="phoneNotificationMessage"
              className="phone_verification_msg_wrapper"
            >
              <div className="d-flex flex-column gap-3">
                <div>
                  <span>{t('settings.label.phoneIdentity1')}</span>
                  <span className="ms-1">
                    {getValues('phone') ?? ''}
                  </span>
                  <span className="me-1">{t('dot')}</span>
                  <span className="ms-1">
                    {t('settings.label.phoneIdentity2')}
                  </span>
                </div>
                <div className="mb-3 pos-rel">
                  <Label
                    dataTestId="verificationInputLabel"
                    htmlFor="verificationCode"
                    
                    className="pb-1"
                    >{t('settings.label.verificationCode')}</Label>
                  <InputField
                    className={`form-input ${
                      submitClicked && verificationCode === ''
                        ? 'error-border'
                        : ''
                    }`}
                    id="verificationCode"
                    maxLength={smsInputMaxLength}
                    data-testid="verification_code"
                    aria-describedby="verification_error"
                    value={verificationCode}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      setVerificationCode(e.target.value.replace(NumberFormat, ''));
                      setInValidOPTError(false)
                    }}
                  />

                  {/* Error message for verification code */}
                  {submitClicked && (verificationCode.length < smsInputMaxLength)  ? (
                    <div className="mt-1">
                      <ErrorMessage
                        data-testid={'verificationCodeError'}
                      >
                      {t('settings.errors.verificationCode')}

                      </ErrorMessage>
                    </div>
                  ) : null }

                 

                  {submitClicked && inValidOPTError  ? (
                    <div className="mt-1">
                      <ErrorMessage
                        data-testid={'verificationCodeError'}
                      >
                      {t('settings.errors.inValidSMSToken')}

                      </ErrorMessage>
                    </div>
                  ) : null }

                  
                  
                  <Button
                    variant="btn-links text-decoration-underline bg-transparent border-0 p-0 mt-1"
                    dataTestId="resendVerificationTitle"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.preventDefault();
                      onSubmit();
                    }}
                   >
                    {t('settings.label.resendVerification')}
                   </Button>
                </div>
                <div className="d-flex flex-lg-row flex-md-row flex-column gap-lg-3 gap-md-3 gap-4 mb-2">
                  <Button
                    dataTestId={'submit_code'}
                   
                    variant={
                      verificationCode.length < smsInputMaxLength
                        ? 'btn_block_mob disabled_btn'
                        : 'primary_btn btn_block_mob'
                    }
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.preventDefault();
                      setSubmitClicked(true);
                      if(verificationCode.length === smsInputMaxLength){
                        handleOtpSubmit();
                      }
                      
                    }}
                  >
                    {t('settings.label.submitCode')}
                  </Button>
                  <Button
                    dataTestId={'verification_cancel_button'}
                    variant={'secondary_btn'}
                    onClick={closePopUpConfirmModal}
                  >
                  {t('settings.label.cancel')}
                  </Button>
                </div>
              </div>
            </div>
          </ModalOverlay>
    )
};

export default ConfirmIdentityModal;