import { useContext } from 'react';
import {
  MyPlan,
  Benefits,
  PlanDocuments,
  CoveredEligibleMembers,
  MemberIdCard,
  EnrollInBenefits,
  MemberProfile,
  Authorizations,
  FormAndDocument,
  MedicalClaims,
  PharmacyClaims,
  VirtualVisitCare,
  PayYourSentaraBill,
  TreatmentEstimateCosts,
  WellnessPrograms,  
} from 'pages';
import { GlobalContext, isAvMed } from 'context';
import { useTranslation } from 'react-i18next';
import { myPlanUrl, MyPlanTabs, Navigate } from 'common';

const UrlLinks = () => {
  const { getFeature, isMemberTabDefault } = useContext(GlobalContext);
  const { t } = useTranslation();
  // JSON to render the Side Menu and toggle for display rule"

  const benefitsUrl = [
    {
      id: 1,
      to: isMemberTabDefault ? `${myPlanUrl + MyPlanTabs.memberDetailID}`: `${myPlanUrl + MyPlanTabs.overViewID}`,
      title: t('navigation.label.yourPlan'),
      onclick: null,
      toggle: getFeature?.YourPlan,
      content: <MyPlan />,
    },
    {
      id: 2,
      to: '/home/benefits',
      title: t('navigation.label.benefits'),
      onclick: null,
      toggle: getFeature?.Benefits,
      content: <Benefits />,
    },
    {
      id: 3,
      to: '/home/plan-documents',
      title: t('navigation.label.planDocuments'),
      onclick: null,
      toggle: getFeature?.PlanDocuments,
      content: <PlanDocuments />,
    },
    {
      id: 4,
      to: '/home/covered-eligible-members',
      title: t('navigation.label.coveredEligibleMembers'),
      onclick: null,
      toggle: getFeature?.CoveredEligibleMembers,
      content: <CoveredEligibleMembers />,
    },
    {
      id: 5,
      to: '/home/member-id-card',
      title: t('navigation.label.memberIDCard'),
      onclick: null,
      toggle: getFeature?.MemberidCard,
      content: <MemberIdCard />,
    },
    {
      id: 6,
      to: '#',
      title: t('navigation.label.dentalCoverage'),
      onclick: 'DentaQuest',
      toggle: getFeature?.DentaQuest,
      content: null,
    },
    {
      id: 7,
      to: '#',
      title: t('navigation.label.manageRenewPolicy'),
      onclick: 'ManageRenewPolicy',
      toggle: getFeature?.ManageorRenewPolicy,
      content: null,
    },
    {
      id: 8,
      to: '#',
      title: t('navigation.label.manageHealthSavingsAccount'),
      onclick: 'HSA',
      toggle: getFeature?.ManageHSA,
      content: null,
    },
    {
      id: 9,
      to: '#',
      title: t('navigation.label.manageHRA'),
      onclick: 'HRA',
      toggle: getFeature?.ManageHealthReimbursementAccount,
      content: null,
    },
    {
      id: 10,
      to: '/home/enroll-benefits',
      title: t('navigation.label.enrollBenefit'),
      onclick: null,
      toggle: getFeature?.EnrollInBenefits,
      content: <EnrollInBenefits />,
    },
    {
      id: 11,
      to: '#',
      title: t('navigation.label.visionCare'),
      onclick: 'VisionCare',
      toggle: getFeature?.VisionCare,
      content: null,
    },
    {
      id: 12,
      to: '/home/authorizations',
      title: t('navigation.label.authorizations'),
      onclick: null,
      toggle: getFeature?.Authorizations,
      content: <Authorizations />,
    },
    {
      id: 13,
      to: '/home/forms-and-documents',
      title: t('navigation.label.formsDocuments'),
      onclick: null,
      toggle: getFeature?.FormsandDocuments,
      content: <FormAndDocument />,
    },
  ];
  const paymentClaims = [
    {
      id: 6,
      to: '/home/pay-your-sentara-bill',
      title: t('navigation.label.payYourSentaraBill'),
      onclick: null,
      toggle: getFeature?.PayYourSentaraBill,
      content: <PayYourSentaraBill />,
    },
    {
      id: 1,
      to: '#',
      title: t('navigation.label.payMonthlyPremium'),
      onclick: 'WellsFargo',
      toggle: getFeature?.PayMonthlyPremium,
      content: null,
    },
    {
      id: 2,
      to: '/home/medical-claims',
      title: t('navigation.label.medicalClaimsExplanation'),
      onclick: null,
      toggle: getFeature?.MedicalClaims,
      content: <MedicalClaims />,
    },
    {
      id: 3,
      to: '/home/behavioral-claims',
      title: t('navigation.label.behavioral'),
      onclick: null,
      toggle: getFeature?.BehavioralClaims,
      content: <MedicalClaims />,
    },

    {
      id: 4,
      to: '/home/pharmacy-claims',
      title: t('navigation.label.pharmacy'),
      onclick: null,
      toggle: getFeature?.PharmacyClaims,
      content: <PharmacyClaims />,
    },
    {
      id: 5,
      to: '/home/costs-calculate',
      title: t('navigation.label.calculateTreatmentCosts'),
      onclick: null,
      toggle: getFeature?.EstimateTreatmentCosts && (getFeature?.IBMEstimateTreatmentCosts || getFeature?.HSQEstimateTreatmentCosts),
      content: <TreatmentEstimateCosts />,
    },   
  ];
  const doctorsMedication = [
    {
      id: 1,
      to: '#',
      title: t('navigation.label.findADoctor'),
      onclick: 'FindADoctor',
      toggle: getFeature?.FindADoctor,
      content: null,
    },
    {
      id: 2,
      to: '#',
      title: t('navigation.label.changePrimaryCare'),
      onclick: 'ChangePrimaryCare',
      toggle: getFeature?.ChangePCP,
      content: null,
    },

    {
      id: 3,
      to: '#',
      title: t('navigation.label.phamacyResources'),
      onclick: 'PharmacyResources',
      toggle: getFeature?.PharmacyResources,
      content: null,
    },
    {
      id: 4,
      to: getFeature?.VirtualFirstHealthPlan ? '/home/virtual-visit-care':'#',
      title: t('navigation.label.virtualVisit'),
      onclick: getFeature?.VirtualFirstHealthPlan ? null : 'VirtualVisit',
      toggle: getFeature?.MDLiveVirtualVisit,
      content: getFeature?.VirtualFirstHealthPlan ? <VirtualVisitCare /> : null,
    },
    {
      id: 5,
      to: '#',
      title: t('navigation.label.hcsEmployeeHealthWellnessCtr'),
      onclick: 'HCSEmployeeHealthWellnessCtr',
      toggle: getFeature?.CareATC,
      content: null,
    },
    {
      id: 6,
      to: '#',
      title: t('navigation.label.travelAssistance'),
      onclick: 'TravelAssistance',
      toggle: getFeature?.EmergencyTravelAssistance,
      content: null,
    },
    {
      id: 7,
      to: '#' ,
      title: t('navigation.label.scheduleLabWork'),
      onclick: 'ScheduleLabWork',
      toggle: getFeature?.ScheduleLabWork,
      content: null,
    }
  ];

  const getHealthy = [
    {
      id: 1,
      to: '#',
      title: t('navigation.label.personalHealthRecord'),
      onclick: 'PersonalHealthRecord',
      toggle: getFeature?.PersonalHealthRecord,
      content: null,
    },
    {
      id: 2,
      to: '#',
      title: t('navigation.label.personalHealthAssessment'),
      onclick: 'PersonalHealthAssessment',
      toggle: getFeature?.PersonalHealthAssessment,
      content: null,
    },
    {
      id: 3,
      to:'#',
      title: t('navigation.label.wellnessTools'),
      onclick: 'WellnessTools',
      toggle: getFeature?.WellnessTools,
      content: null,
    },
    {
      id: 4,
      to: '#',
      title: t('navigation.label.viewCarePlan'),
      onclick: 'ViewCarePlan',
      toggle: getFeature?.ViewCarePlan,
      content: null,
    },
  ];
  const guestBenefitsUrl = [
    {
      id: 1,
      to: '/home/enroll-benefits',
      title: t('navigation.label.enrollBenefit'),
      onclick: null,
      toggle: true,
      content: <EnrollInBenefits />,
    },
  ];


  const guestWellnessUrl = [
    {
      id: 1,
      to: '/home/wellness-programs',
      title: t('navigation.label.wellnessPrograms'),
      onclick: null,
      toggle: true,
      content: <WellnessPrograms />,
    },
  ];

  const memberProfileUrl = [
    {
      id: 1,
      to: Navigate.memberProfile,
      title: t('navigation.label.memberProfile'),
      onclick: null,
      toggle: true,
      content: <MemberProfile />,
    },
  ];
  const designatedRepresentativeUrl = [
    {
      id: 1,
      to: '/home/forms-and-documents',
      title: t('navigation.label.formsDocuments'),
      onclick: null,
      toggle: true,
      content: <FormAndDocument />,
    },
  ];
  return {
    benefitsUrl,
    paymentCalims: paymentClaims,
    doctorsMedication,
    getHealthy,
    guestBenefitsUrl,
    guestWellnessUrl,
    designatedRepresentativeUrl,
    memberProfileUrl,
  };
};

export default UrlLinks;
