import {
  Headings,
  Button,
  Image,
  Label,
  Paragraph,
  Loader,
  ErrorMessage,
} from '@sentaraui/optimahealth_web';
import {
  LabServicePopUp,
  PrimaryCarePopUp,
  NurseOnCallPopUp,
  UrgentCarePopUp,
  BehavioralPopUp,
  CareTile,
} from 'templates';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  SENTARA_URL,
  navigateToExternalUrl,
  physicalTherapy,
  pharmacyTools,
  isUnAuthorizedError,
  unAuthorizedErrorHandler,
  getDecryptedValue,
  UserDetails,
  VIRGINIA_BEACH_URL,
  Navigate,
} from 'common';
import { useNavigate } from 'react-router-dom';
import {
  useMemberEligibility,
  useVirtualVisitCategory,
  VirtualCareCategoryResponse,
} from '@sentara/sentara-api-hooks-core';
import { GlobalContext } from 'context';

interface EligibilityItems {
  type?: CodeType;
  value?: string;
  [key: string]: any;
}
interface Coding {
  code?: string;
  display?: string;
  [key: string]: any;
}
interface CodeType {
  coding?: Coding;
  [key: string]: any;
}

function VirtualVisitCare() {
  const { t } = useTranslation();

  const effectRan = useRef(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [errorMessage, showErrorMessage] = useState<boolean>(false);

  const [virtualVisitDashboard, setVirtualVisitDashboard] =
    useState<VirtualCareCategoryResponse>();

  const [memberEligibilityBenefitsList, setMemberEligibilityBenefitsList] =
    useState<EligibilityItems[]>();

  const { MemberEligibilityRequest } = useMemberEligibility();
  const { VirtualVisitCategoryRequest } = useVirtualVisitCategory();
  const { loginMemberId, getFeature } = useContext(GlobalContext);

  const [sentaraBenefitObject, setSentaraBenefitObject] = useState<
    Record<string, any>
  >({});
  const [mdliveBenefitObject, setMdLiveBenefitObject] = useState<
    Record<string, any>
  >({});

  useEffect(() => {
    const fetchVirtualCare = async (accessToken: string) => {
      const response = await VirtualVisitCategoryRequest(accessToken);
      if (response?.data != null) {
        setVirtualVisitDashboard(response.data);
      } else if (response?.errorCode) {
        handleError(response.errorCode);
      }
      setLoading(false);
    };

    const fetchToken = async () => {
      setLoading(true);
      const bearerToken = await getDecryptedValue(UserDetails.bearerToken);
      if (loginMemberId !== null) {
        const requestBody = {
          memberId: loginMemberId,
        };
        const eligibilityResponse = await MemberEligibilityRequest(
          bearerToken,
          requestBody
        );
        if (eligibilityResponse?.error) {
          handleError(eligibilityResponse.error);
        } else {
          setMemberEligibilityBenefitsList(
            eligibilityResponse?.data?.eligibilityPlans[0]?.items
          );
        }
        fetchVirtualCare(bearerToken);
      } else {
        setLoading(false);
      }
    };

    if (effectRan.current) return;
    fetchToken();
    effectRan.current = true;
  }, [
    virtualVisitDashboard,
    MemberEligibilityRequest,
    VirtualVisitCategoryRequest,
    loginMemberId,
  ]);

  const handleError = (errorCode: string) => {
    setLoading(false);
    if (isUnAuthorizedError(errorCode)) {
      unAuthorizedErrorHandler();
    } else {
      showErrorMessage(true);
    }
  };

  useEffect(() => {
    if (!memberEligibilityBenefitsList || !virtualVisitDashboard?.result)
      return;
    const copayObject = Object.fromEntries(
      memberEligibilityBenefitsList.map(({ type, value }) => [
        type?.coding?.code,
        value,
      ])
    );
    const benefitObjects: Record<string, Record<string, any>> = {
      mdliveCoPay: {},
      mdliveCoPayInsurance: {},
      sentaraCoPayIndex: {},
      sentaraCoPayInsuranceIndex: {},
    };
    virtualVisitDashboard.result.forEach(({ description, ...rest }: any) => {
      Object.entries(benefitObjects).forEach(([key, target]) => {
        if (rest[key] && copayObject[rest[key]]) {
          target[
            `${description}${
              key === 'mdliveCoPayInsurance' ||
              key === 'sentaraCoPayInsuranceIndex'
                ? ' Insurance'
                : ''
            }`
          ] = copayObject[rest[key]];
        }
      });
    });
    setSentaraBenefitObject({
      ...benefitObjects.sentaraCoPayIndex,
      ...benefitObjects.sentaraCoPayInsuranceIndex,
    });
    setMdLiveBenefitObject({
      ...benefitObjects.mdliveCoPay,
      ...benefitObjects.mdliveCoPayInsurance,
    });
  }, [memberEligibilityBenefitsList, virtualVisitDashboard]);

  type ModalState = {
    isLabOpen: boolean;
    isNurseOpen: boolean;
    isPrimaryCareOpen: boolean;
    isUrgentCareOpen: boolean;
    isBehavioralHealthOpen: boolean;
  };

  const [modalStates, setModalStates] = useState<ModalState>({
    isLabOpen: false,
    isNurseOpen: false,
    isPrimaryCareOpen: false,
    isUrgentCareOpen: false,
    isBehavioralHealthOpen: false,
  });

  const setModalState = (modalName: keyof ModalState, value: boolean) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: value,
    }));
  };

  const handleEAP = () => {
    navigateToExternalUrl(
      getFeature?.VirtualVisitEAP ? VIRGINIA_BEACH_URL : SENTARA_URL
    );
  };
  const handleModalOpen = (modalName: keyof ModalState) => {
    setModalState(modalName, true);
  };

  const onScheduleLabVisitClick = () => handleModalOpen('isLabOpen');
  const onScheduleNurseVisitClick = () => handleModalOpen('isNurseOpen');
  const onPrimaryCareClick = () => handleModalOpen('isPrimaryCareOpen');
  const onUrgentCareClick = () => handleModalOpen('isUrgentCareOpen');
  const onBehavioralHealthClick = () =>
    handleModalOpen('isBehavioralHealthOpen');
  const handleNavigation = (url: string) => () => navigate(url);

  const navigate = useNavigate();

  const NO_CHARGE = 'No Charge';
  const COPAY_MESSAGE = (amount: string) => `$${amount} copay`;
  const COINSURANCE_MESSAGE = (percentage: string) => `${percentage}% coinsurance`;

  function closeVirtualVisitModal() {
    setModalStates({
      isLabOpen: false,
      isNurseOpen: false,
      isPrimaryCareOpen: false,
      isUrgentCareOpen: false,
      isBehavioralHealthOpen: false,
    });
  }

  enum VirtualTiles {
    MENTAL_HEALTH_BEHAVIOR_THERAPY = 'Mental Health and Behavioral Therapy',
    PHARMACY_TOOLS = 'Pharmacy',
    URGENTCARE = 'Urgent Care',
    NURSE_ON_CALL = '24/7 Nurse Advice Line',
    EMPLOYEE_ASSISTANCE_PROGRAM = 'Employee Assistance Program',
    PRIMARY_CARE_CHRONIC_CONDITION = 'Primary Care and Chronic Care',
    LAB = 'Lab',
    PHYSICAL_THERAPY = 'Physical Therapy',
  }

 const copayAmount = (
    benefitObject: Record<string, any>,
    titleKey: string
  ): string => {
    const copay = benefitObject[titleKey]?.toString();
    const insuranceCopay = benefitObject[`${titleKey} Insurance`]?.toString();
  
    if (copay && copay !== '0') {
      return COPAY_MESSAGE(copay);
    }
  
    if (insuranceCopay && insuranceCopay !== '0') {
      return COINSURANCE_MESSAGE(insuranceCopay);
    }
  
    return NO_CHARGE;
  };

  const popups = [
    { isOpen: modalStates.isLabOpen, Component: LabServicePopUp },
    {
      isOpen: modalStates.isPrimaryCareOpen,
      Component: PrimaryCarePopUp,
      mdliveCopay: copayAmount(
        mdliveBenefitObject,
        VirtualTiles.PRIMARY_CARE_CHRONIC_CONDITION
      ),
      sentaraCopay: copayAmount(
        sentaraBenefitObject,
        VirtualTiles.PRIMARY_CARE_CHRONIC_CONDITION
      ),
    },
    { isOpen: modalStates.isNurseOpen, Component: NurseOnCallPopUp },
    {
      isOpen: modalStates.isUrgentCareOpen,
      Component: UrgentCarePopUp,
      mdliveCopay: copayAmount(mdliveBenefitObject, VirtualTiles.URGENTCARE),
      sentaraCopay: copayAmount(sentaraBenefitObject, VirtualTiles.URGENTCARE),
    },
    {
      isOpen: modalStates.isBehavioralHealthOpen,
      Component: BehavioralPopUp,
      mdliveCopay: copayAmount(
        mdliveBenefitObject,
        VirtualTiles.MENTAL_HEALTH_BEHAVIOR_THERAPY
      ),
      sentaraCopay: copayAmount(
        sentaraBenefitObject,
        VirtualTiles.MENTAL_HEALTH_BEHAVIOR_THERAPY
      ),
    },
  ];

  const tileActions: Record<VirtualTiles, () => void> = {
    [VirtualTiles.MENTAL_HEALTH_BEHAVIOR_THERAPY]: onBehavioralHealthClick,
    [VirtualTiles.PHARMACY_TOOLS]: handleNavigation(pharmacyTools),
    [VirtualTiles.URGENTCARE]: onUrgentCareClick,
    [VirtualTiles.NURSE_ON_CALL]: onScheduleNurseVisitClick,
    [VirtualTiles.EMPLOYEE_ASSISTANCE_PROGRAM]: handleEAP,
    [VirtualTiles.PRIMARY_CARE_CHRONIC_CONDITION]: onPrimaryCareClick,
    [VirtualTiles.LAB]: onScheduleLabVisitClick,
    [VirtualTiles.PHYSICAL_THERAPY]: handleNavigation(physicalTherapy),
  };

  const clickTile = (title: string) => {
    if (title in tileActions) {
      const action = tileActions[title as VirtualTiles];
      action();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      navigate(Navigate.dashboard);
    }
  };

  return (
    <>
      {loading && (
        <div>
          <Loader />
        </div>
      )}

      {/* To show the internalServerError message */}
      {errorMessage && <ErrorMessage>{t('internalServerError')}</ErrorMessage>}

      <div>
        <div
          data-TestId="back"
          className="d-flex flex-row align-items-center cursor-pointer"
          onClick={() => navigate(Navigate.dashboard)}
          role="button"
          tabIndex={0}
          onKeyDown={handleKeyDown}
        >
          <span className="arrow-back-black" />
          <Headings
            level={1}
            text={t('virtualVisitCare.content.heading')}
            variant="semiBold"
            data-testid={'getCareNowHeader'}
          />
        </div>
        <div className="mt-3 d-flex flex-column fs-5">
          <Label dataTestId={'getCareServices'}>
            {t('virtualVisitCare.content.getCareServices')}
          </Label>
          <Label className="mb-3 font-size-14" dataTestId={'getCareDisclaimer'}>
            {t('virtualVisitCare.content.getCareDisclaimer')}
          </Label>
        </div>

        <div className="virtual-border pb-4 mb-5">
          <div className="virtual-grid-cont grid-cont">
            <div
              className="left-border-white"
              aria-hidden="true"
              data-focusable="false"
            ></div>
            {virtualVisitDashboard?.result.map((item) => (
              <CareTile
                key={item.name ?? ''}
                className="virtual-grid-col"
                heading={item.name ?? ''}
                fontAwesomeIconKey={item.fontAwesomeIconKey ?? ''}
                onClick={() => clickTile(item.name ?? '')}
              />
            ))}
            <div
              className="bottom-border-white"
              aria-hidden="true"
              data-focusable="false"
            ></div>
          </div>
        </div>

        <div className="my-4">
          <Headings
            level={1}
            text={t('virtualVisitCare.content.title')}
            variant="semiBold"
            data-testid="whatWeOfferHeader"
          />
        </div>

        <div className="d-flex flex-column my-2">
          <div className="row mb-2 ">
            <div className="mb-3 col-md-6 order-md-first mt-2 mt-md-0">
              <Headings
                level={3}
                text={t('virtualVisitCare.content.virtualVisit')}
                variant={'semiBold'}
              />
              <div className="mt-4">
                <Paragraph data-testid={'whatWeOfferContent'}>
                  {t('virtualVisitCare.content.whatWeOfferContent')}
                </Paragraph>
                <Paragraph
                  className="mb-4 font-size-14"
                  data-testid={'disclaimerContent'}
                >
                  {t('virtualVisitCare.content.disclaimerContent')}
                </Paragraph>
                <Button
                  data-testid={'scheduleVirtualVisit'}
                  variant={'primary_btn btn_block_mob'}
                  onClick={onPrimaryCareClick}
                >
                  {t('Schedule Your Virtual Visit')}
                </Button>
                {popups.map(
                  ({ isOpen, Component, mdliveCopay, sentaraCopay }) =>
                    isOpen && (
                      <Component
                        key={Component.name}
                        closeModal={closeVirtualVisitModal}
                        mdliveCopay={mdliveCopay}
                        sentaraCopay={sentaraCopay}
                      />
                    )
                )}
              </div>
            </div>
            <div className="col-md-6 order-first">
              <iframe
                className="rounded-3 mt-4"
                id="video"
                width="100%"
                height="225"
                allowFullScreen
                src="https://bcove.video/3XBkwd2"
                data-testid="video-iframe"
                title="Virtual Visit Video"
              />
            </div>
          </div>

          <div className="row my-4 py-4">
            <div className="mb-4 col-md-6">
              <Image
                data-testid={'virtualVisitLogo'}
                className="w-100 h-55 rounded-4"
                path={'/virtualvisitlogo.png'}
              />
            </div>
            <div className="mb-3 col-md-6">
              <Headings
                level={3}
                text={t('virtualVisitCare.content.additionalBenefitsTitle')}
                variant={'semiBold'}
              />
              <div className="mt-4">
                <Paragraph data-testid={'additionalBenefitsContent'}>
                  {t('virtualVisitCare.content.additionalBenefitsContent')}
                </Paragraph>
                <div className="mt-4">
                  <Button
                    data-testid={'Learn More'}
                    variant={'primary_btn btn_block_mob'}
                    onClick={() => {
                      handleEAP();
                    }}
                  >
                    {t('Learn More')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default VirtualVisitCare;
