import React from 'react';
import { useTranslation } from 'react-i18next';
import MemberIdImg from '../../assets/images/memberidcard_img.png';

function MemberIdOverlay() {
  const { t } = useTranslation();
  return (
    <div className="memoverlay-content">
      <h5
        className="memoverlay-title mb-2"
        data-testid="memIDCardToolTipHeader"
      >
        {t('activation.messages.memberIDCardToolTipHeader')}
      </h5>
      <p className="mb-3" data-testid="memIDCardToolTipDescOne">
        {t('activation.messages.memberIDCardToolTipText1')}{' '}
      </p>
      <div className="pb-3 text-center">
        <img
          src={MemberIdImg}
          alt="Member Id Card"
          className="img-fluid"
        />
      </div>
      <p className="mb-0" data-testid="memIDCardToolTipDescTwo">
        {t('activation.messages.memberIDCardToolTipText4')}{' '}
        {t('activation.messages.memberIDCardToolTipText5')}
      </p>
    </div>
  );
}

export default MemberIdOverlay;
