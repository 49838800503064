import './assets/css/common.scss';
import 'bootstrap/dist/js/bootstrap.js';
import { Route, Routes } from 'react-router-dom';
import {
  RequireAuth,
  Navigate,
  CSR_PROXY_REVOKE_URL,
  CSR_PROXY_UPDATE_URL,
  PROXY_APPROVE_URL,
  PROXY_EXPIRED_URL,
  PROXY_REGISTER_URL,
  signIn,
  AUTH_SUCCESS_URL,
  AuthProvider,
} from 'common';
import {
  CSRGuardianUpdate,
  ProxyApprove,
  CSRGuardianRevoke,
  ProxyRegister,
  ProxyExpiredFormWrapper,
} from 'templates';
import {
  ForgotUsername,
  ForgotPassword,
  Form,
  SignInForm,
  HomePage,
  ProxyAuth,
  IAMAuthSuccess
} from 'pages';
import { useContext, useEffect } from 'react';
import { GlobalContext } from './context';

import { AppWrapper } from 'common/AppWrapper';
const App = ({
  header,
  authToken,
  xAuthToken,
  xSentaraTokenID,
}: {
  header: Record<string, string | undefined>;
  authToken: string;
  xAuthToken: string;
  xSentaraTokenID: string;
}) => {
  const { setUserEncodedData } = useContext(GlobalContext);


  useEffect(() => {
    const func = async () => {
      // set anynomous user LD flags before login.
      setUserEncodedData(
        JSON.stringify({
          key: 'anonymous',
        })
      );
    };
    func();
  }, []);

  return (
      <AppWrapper header={header} authToken={authToken} xAuthToken={xAuthToken} xSentaraTokenID={xSentaraTokenID}>
        <AuthProvider>
          <Routes>
            <Route path={signIn} element={<SignInForm />} />
            <Route
              path="*"
              element={
                <RequireAuth>
                  <HomePage />
                </RequireAuth>
              }
            />
            <Route path="/reset-password" element={<ForgotPassword />} />
            <Route path="/login/forgot-username" element={<ForgotUsername />} />
            <Route path={Navigate.registration} element={<Form />} />
            <Route path="/proxy-authentication" element={<ProxyAuth />} />
            <Route
              path={PROXY_EXPIRED_URL}
              element={<ProxyExpiredFormWrapper />}
            />
            <Route
              path={CSR_PROXY_UPDATE_URL}
              element={<CSRGuardianUpdate />}
            />
            <Route path={PROXY_APPROVE_URL} element={<ProxyApprove />} />
            <Route
              path={CSR_PROXY_REVOKE_URL}
              element={<CSRGuardianRevoke />}
            />
            <Route path={PROXY_REGISTER_URL} element={<ProxyRegister />} />
            <Route path={AUTH_SUCCESS_URL} element={<IAMAuthSuccess />} />

            <Route
              path="/home"
              element={
                <RequireAuth>
                  <HomePage />
                </RequireAuth>
              }
            />
          </Routes>
        </AuthProvider>
      </AppWrapper>
  );
};

export default App;
