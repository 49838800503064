import { useEffect, useState } from 'react';
import { t } from 'i18next';
import {
  useProfileImage,
  useUploadPhoto,
} from '@sentara/sentara-api-hooks-core';
import { UserInitialsAvatar } from 'organisms';
import {
  ErrorMessage,
  Image,
  Loader,
  Input,
  Paragraph
} from '@sentaraui/optimahealth_web';
import { ProfileInformations } from './interface';

const UserImageAvatar = ({ firstName, lastName }: ProfileInformations) => {
  const { getProfileImage, state } =
    useProfileImage(); /* profile image API to get profile image */
  const { uploadPhoto } =
    useUploadPhoto(); /* upload photo API for profile image */
  const { image: profilePhoto } = state; /* profile photo state */
  const [errorMessage, setErrorMessage] =
    useState(''); /* error message state */
  const [loader, setLoader] = useState(false);

  /* validate selected file size */
  const ValidateSelectedInputFile = (file: File) => {
    if (file.size > 9216983) {
      setErrorMessage(t('settings.label.profileImageSizeError') ?? '');
      return false;
    }
    return true;
  };

  const onImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event?.preventDefault();
    setErrorMessage('');
    setLoader(true);
    if (event.target?.files?.length) {
      const inputFile = event.target.files[0];
      const valid = ValidateSelectedInputFile(inputFile);
      if (valid) {
        const formData: any = new FormData();
        formData.append('image', inputFile);
        await uploadPhoto(formData);
        setLoader(false);
      }
    }
  };
  /* get profile image */
  useEffect(() => {
    (async () => {
      await getProfileImage();
    })();
  }, [getProfileImage]);

  return (
    <div>
      <div className="flex-no-wrap align-items-center pos-rel mb-4 row">
        {loader ? (
          <div data-testid="loader_changeprofileimage">
            <Loader />
          </div>
        ) : (
          profilePhoto && (
            <div className="col-lg-12 col-md-12 col-sm-6 d-flex col-6 justify-content-center">
              <div className="d-flex profile_image rounded-circle mx-lg-auto mx-md-auto justify-content-center">
                <Image
                  dataTestId="profileImage"
                  alttext={`${firstName} ${lastName} Profile Image`}
                  className="img-fluid d-block"
                  path={'data:image/png;base64,' + profilePhoto}
                />
              </div>
            </div>
          )
        )}
        {!state.loading && profilePhoto.length <= 0 && (firstName || lastName) ? (
          <div className="col-lg-12 col-md-12 col-sm-6 col-6">
            <UserInitialsAvatar firstName={firstName} lastName={lastName} />
          </div>
        ) : null}
        <div className="col-lg-12 col-md-12 col-sm-auto col-auto text-center flex-1 pt-0 pt-lg-4 pt-md-4 px-0 mt-lg-2 mt-md-2">
          {errorMessage && <ErrorMessage children={errorMessage} />}
          <div className="pos-rel mt-2 mb-2">
            <label aria-hidden="true">
              <Paragraph className="upload-photo">
                {t('settings.label.changeProfilePhoto')}
              </Paragraph>
            </label>
            <Input
              dataTestId="uploadProfileImage"
              type="file"
              name="photo"
              id="upload-photo"
              onChange={onImageChange}
              accept="image/png, image/jpg, image/jpeg"
              data-focusable="false"
              role="link"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserImageAvatar;
