import AVMED_LOGO from "../../assets/images/avmed-logo.svg";
import SENTARA_LOGO from '../../assets/images/logo-common.png';
import favicon from "../../assets/images/favicon.ico";
import faviconAvMed from "../../assets/images/faviconAvMed.ico";
export type BrandTypes = {
  brandName: string;
  hostNames?: Array<string>;
  image: { [x: string]: any };
  url : { [x: string]: any };  
};

enum Brand {
  AVMED = "AVMED",
  DEFAULT = "DEFAULT",
}
export type BrandsType = Record<Brand, BrandTypes>;

export const BRAND_CONFIG: BrandsType = {
  AVMED: {
    brandName: "AVMED",
/* eslint-disable */
    hostNames: ["ashy-sky-0cccee60f.3.azurestaticapps.net", 
      "brave-ground-01cba370f.3.azurestaticapps.net",
      "localhost:4000",
       "memberdev.avmed.com",
       "memberqa.avmed.com",
       "memberreg.avmed.com",
       "memberuat.avmed.com",
       "member.avmed.com"      
      ],
    image: {
      Logo: AVMED_LOGO,
      faviconImage:  faviconAvMed,
    },
    url : {
    brandUrl: "https://www.avmed.org",
    }

  },
  DEFAULT: {
    brandName: "DEFAULT",
    hostNames: ['localhost:3000'],
    image: {
      Logo: SENTARA_LOGO,
      faviconImage:  favicon, 
    },
    url : { 
    brandUrl:"https://www.sentarahealthplans.com/members/",
    }
  },
};

export const getBrand = (host: string): BrandTypes => {
  const brand = Object.values(BRAND_CONFIG).find((brand) => {
    return brand.hostNames?.includes(host);
  });

  return brand || BRAND_CONFIG.DEFAULT;
};