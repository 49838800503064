export const memberBaseUrl = 'https://www.sentarahealthplans.com';
export const avmedURL = 'https://www.avmed.org';
export const claimsLinkUrl = 'https://sit1-consumerism-avmed.dxp.nttdataservices.com/en/member?Claims=Claims';
export const authorizationsLinkUrl = 'https://sit1-consumerism-avmed.dxp.nttdataservices.com/en/member?Authorizations=Authorizations';
export const termAndCondition =
  memberBaseUrl + '/company/policies/terms-of-use-and-disclaimer';
export const HCSEmployeeHealthWellnessURL = 'https://www.careatc.com/patients';
export const PRIMARY_CARE_URL = 'https://www.sentara.com/hospitalslocations/sentara-virtual-care/our-care-teams/primary-care';
export const LAB_SERVICES_URL = 'https://www.sentara.com/medicalservices/laboratory-services/laboratory-scheduling?step=map';
export const PHYSICAL_THERAPY_URL = ' https://www.sentara.com/hospitalslocations/sentara-virtual-care/our-care-teams/physical-therapy';
export const SENTARA_URL = 'https://sentaraeap.personaladvantage.com/welcome?scheme=hashcode&o=SENTARA&target=/';
export const VIRGINIA_BEACH_URL = 'https://sentaraeap.personaladvantage.com/welcome?scheme=hashcode&o=VIRGINIABEACH&target=/';
export const SPECIALTY_PHARMAC_URL = 'https://www.sentarahealthplans.com/members/manage-plans/specialty-pharmacy-information';
export const PRESCRIPTION_DELIVERY_URL = 'https://www.sentarahealthplans.com/members/manage-plans/prescription-home-delivery';

export const dropdownValue = '/home/view-tax-summary';
export const launchDarklyURL = 'https://app.launchdarkly.com/sdk/evalx/';
export const launchDarklyKey = '/contexts/';

export const proxyExpiredForm = '/proxy-expired-form';
export const benefitFocusSSOUrl= 'BenefitFocusSSOUrl';
export const vlocityEnrollBenefits= 'Vlocity/EnrollBenefits';
export const memberHome = memberBaseUrl + '/members/';
export const contactUsAnonymous = memberBaseUrl + '/members/contact-us'
export const memberLogin = memberBaseUrl + '/signin';
export const myPlanUrl = '/home/your-plan?id=';
export const formsAndDocumentsUrl = '/home/forms-and-documents';
export const authorizationUrl = '/home/authorizations';
export const memberIDCardUrl = '/home/member-id-card';
export const virtualVisitUrl = '/home/virtual-visit-care';
export const scheduleLabWorkUrl = 'https://myquest.questdiagnostics.com/web/home';
export const benefitsURL = '/home/benefits';
export const medicalClaimsURL = '/home/medical-claims';
export const paySentaraBillURL = '/home/pay-your-sentara-bill';
export const approvalUrl = '/home/proxy-approval';
export const qa_base_url = 'https://webapidevqa.sentara.com/qa4';
export const azureBaseURL = process.env.REACT_APP_BASE_URL?.includes('/qa5')
  ? qa_base_url
  : process.env.REACT_APP_BASE_URL; // Azure functions doesn't have regression environment. So, we are pointing to QA4.
export const caregiverEndPoint = azureBaseURL + '/optimamember/v1/caregiver/';
export const signIn = "/sign-in";
export const formsAndDocuments ="forms-and-documents";
export const contactUs ="contact-us";
export const yourPlan = "/home/your-plan"
export const yourPlanId = '?id=1'
export const claimsSummary = '/home/claims-summary'
export const forgotUsername = '/login/forgot-username'
export const resetPassword = '/reset-password'
export const sentaraHealthPlans = 'https://www.sentarahealthplans.com/members'
export const sentaraHealthPlansURL = 'https://www.sentarahealthplans.com/signin'
export const pharmacyTools = '/home/pharmacy-tools'
export const physicalTherapy = '/home/physical-therapy'
export const expressScript = 'https://www.express-scripts.com/frontend/open-enrollment/shpcomm'
export const prescriptionDrugs  = 'https://www.sentarahealthplans.com/members/manage-plans/prescription-drug-lists'
export const preAuthorization = 'https://sentara-223793.workflowcloud.com/forms/f9b89aaf-bdc1-4e42-b942-ab907160b4c2'
export const pharmacyReimbursement = 'https://shc-p-001.sitecorecontenthub.cloud/api/public/content/d3b69842d8d745f3a576e79813299b45?v=86f62ffd'
export const paySentaraBillSignInURL = 'https://www.sentara.visitpay.com/#/';
export const avmedContactUs = avmedURL + '/contact-us';
export const avMedBrandName ='AVMED';
export const footerContactUs = '/home/contact-us';

const AVMED_FOOTER = {
  termAndCondition: avmedURL + '/terms-of-use/',
  languagesAssistance: avmedURL + '/your-privacy',
  footerNoticeOfNonDiscrimination :  avmedURL + '/notice-of-nondiscrimination/',
  accessibility : avmedURL + '/accessibility-statement/'
}

const SENTARA_FOOTER = {
  termAndCondition: termAndCondition,
  languagesAssistance: memberBaseUrl + '/company/policies/language-assistance',
  footerNoticeOfNonDiscrimination :  memberBaseUrl + '/notice-of-nondiscrimination',
  accessibility: "#"
}

export const retrieveFooterLinks = (isAvMed = false) => {
  return isAvMed ? AVMED_FOOTER : SENTARA_FOOTER
}


const AVMED_URL_CONST = {
  manageRenewPolicy : 'BeneiftAlignSSOUrl',
}

const SENTARA_URL_CONST  = {
  manageRenewPolicy : 'SeibelSSOUrl',
}

export const retrieveUrlLinks = (isAvMed = false) => {
  return isAvMed ? AVMED_URL_CONST : SENTARA_URL_CONST
}

export const WEBMD_WELLNESS_URLS = {
  wellnessToolsURL: 'https://www.webmdhealth.com/SentaraHealthPlans/connect.aspx',
  selfPacedProgramsURL: 'https://www.sentarahealthplans.com/en/members/health-and-wellness/prevention-and-wellness/wellbeing-programs',
  healthyOnDemandURL: 'https://www.sentarahealthplans.com/en/members/health-and-wellness/videos',
  exclusiveDiscountsURL: 'https://marketplace.huskwellness.com/', 
  diabetesPreventionURL: 'https://go.omadahealth.com/sentara',
}