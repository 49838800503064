import React, { useState } from 'react';
import { t } from 'i18next';
import {
  Button,
  ModalOverlayWithoutClose,
  Paragraph,
  Collapsible,
 Link,
} from '@sentaraui/optimahealth_web';
import { useMyDelete } from '@sentara/sentara-api-hooks-core';
import { DeleteAccountPopup } from 'templates';
import {
  signIn,
  footerContactUs,
  errorhandler,
  useDeviceType,
  DeviceTypes,
} from 'common';

const DeleteProfile = ({
  expand = true,
  onExpand = () => {},
  onCollapse = () => {},
}: {
  expand?: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
}) => {
  const { MyDeleteType } = useMyDelete();
  const LINK_BTN_BLOCK_MOB = 'btn-links darkblue-color ps-0 mb-2';
  const PRIMARY_BTN_BLOCK_MOB = 'primary_btn';
  const [isSuccessPopup, setIsSuccessPopup] = useState<boolean>(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isShowContent, setIsShowContent] = useState<boolean>(false);
  const deviceType = useDeviceType();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  // Function to handle account deletion
  const deleteAccount = async () => {
    setIsShowContent(false);
    setIsConfirmOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
  }

  // Function to open the delete account modal
  const submitDelete = (e: any) => {
    setIsOpen(true);
    setIsShowContent(true);
    setIsConfirmOpen(false);
  };

  function closePopUpModal() {
    setIsSuccessPopup(false);
    localStorage.clear();
    window.location.href = signIn;
  }

  const confirmDeleteAccount = async () => {
    const response = await MyDeleteType();
    if (response.errorCode) {
      errorhandler(response?.errorCode);
    } else {
      setIsOpen(false);
      setIsConfirmOpen(false);
      setIsSuccessPopup(true);
    }
  };
  return (
    <Collapsible
      title={t('settings.label.deleteAccount')}
      id={'deleteAccount'}
      isMobile={isMobile}
      key={`${t('settings.label.deleteAccount')}-${
        isMobile ? 'mobile' : 'desktop'
      }`}
      expand={expand}
      onExpand={onExpand}
      onCollapse={onCollapse}
      className="mb-2 pb-2"
    >
      <div className="set_my_preferences">
        <div className="border_wrapper">
          <div className="user_address_wrapper">
            {/* Render confirmation message and button to delete account */}
            <Paragraph
              className="pb-3 mb-2"
              data-testid="confirmationDeleteAccountInfo"
            >
              {t('settings.label.confirmationDeleteAccountInfo')}
              <Link
                to={footerContactUs}
                data-testid="customerSupportLink"
                className="gold-link text-decoration-underline"
              >
                {t('settings.label.customerSupportLink')}
              </Link>
            </Paragraph>
            <Button
              dataTestId={'deleteAccountButton'}
              variant={LINK_BTN_BLOCK_MOB}
              onClick={(e: any) => {
                e.preventDefault();
                submitDelete(e);
              }}
            >
              {t('settings.label.continueToDeleteAccount')}
            </Button>
            {/* Render the DeleteAccountPopup component */}
            <DeleteAccountPopup
              isOpen={isOpen}
              closeModal={closeModal}
              isShowContent={isShowContent}
              variant={PRIMARY_BTN_BLOCK_MOB}
              deleteAccount={deleteAccount}
              isConfirmOpen={isConfirmOpen}
              confirmDeleteAccount={confirmDeleteAccount}
            />
            <ModalOverlayWithoutClose
              isOpen={isSuccessPopup}
              overlayInfo="Modal information"
            >
              <div
                data-testid="accountDeleteSuccessModal"
                className="d-flex flex-column align-items-center"
              >
                <h5
                  data-testid="loggedMessage"
                  className="label_logged_message"
                >
                  {t('settings.label.accountDeleteSuccessMsg1')}
                </h5>
                <br />
                <Button
                  dataTestId="okButton"
                  className="primary_btn btn_block_mob"
                  onClick={closePopUpModal}
                >
                  {t('appModel.label.ok')}
                </Button>
              </div>
            </ModalOverlayWithoutClose>
            {/* Delete account end */}
          </div>
        </div>
      </div>
    </Collapsible>
  );
};

export default DeleteProfile;
