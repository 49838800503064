import  {  useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { caregiverEndPoint, myPlanUrl, MyPlanTabs, errorhandler } from 'common';
import { GlobalContext } from 'context';
import { useTranslation } from 'react-i18next';
import { CSRProxy } from '@sentara/caregiver';


const CSRProxyForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getFeature, getAuthToken, xauthToken, xsentaraTokenID } = useContext(GlobalContext);
  const handleCallback = (type: string, response:any) => {
      if (type === t('proxy.label.canceled')) {
        return navigate(`${myPlanUrl + MyPlanTabs.overViewID}`);
      } else if (type === t('proxy.label.apiError')) {
          if(response?.errorCode) {
              errorhandler(response.errorCode);
      }
    }
  };

  return (
    <div data-testid="CSRProxy">
      <CSRProxy
        flow="member-register"
        callBack={handleCallback}
        profileApiSwitch={getFeature?.AccountSettings}
        baseURL={process.env.REACT_APP_BASE_URL || ""}
        caregiverBaseURL={caregiverEndPoint}
        authToken={getAuthToken || ''}
        OcpApimSubscriptionKey={process.env.REACT_APP_SUBSCRIPTION_KEY || ""}
        sentaraAuthIdToken= {xauthToken}
        sentaraTokenId= {xsentaraTokenID || ''}
      />
    </div>
  );
};

export default CSRProxyForm;
