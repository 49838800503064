import { useEffect, useState, useContext } from 'react';
import {DocumentLink} from 'organisms';
import { useDocumentLinks } from '@sentara/sentara-api-hooks-core';
import { useTranslation } from 'react-i18next';
import { errorhandler } from 'common';
import {
  Headings,
  ContactInfo,
  Loader
} from '@sentaraui/optimahealth_web';
import { GlobalContext } from 'context';


const FormAndDocument = () => {
  const { DocumentLinksType } =
    useDocumentLinks(); /* Document Links type API call to get document link */
  const [documentLink, setDocumentLink] = useState<any>(); /* set api result data */
  useDocumentLinks();
  const [loading, setLoading] = useState(false);
  /* Document Links type API call to get document link */
  const { t } = useTranslation(); /* To translate for internationalization */

  const [getPhone, setPhone] =
    useState<any>(); /* set service number in Phone from context */
  /* call to documentlink API and get service number from context */

  const {
    serviceNumber
  } = useContext(GlobalContext);

  async function asyncCall() {
    const result = await DocumentLinksType();
    if (result?.data !== null) {
      setDocumentLink(result?.data);
    } else if (result?.errorCode) {
      errorhandler(result?.errorCode);
    }
    setLoading(false);
      setPhone(JSON.parse(serviceNumber || '{}'));
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await asyncCall();
    })();
  }, []);
  
  return (
    <>
      {loading && <Loader />}
      <div className="mb-4">
        <Headings
          level={1}
          text={t('navigation.label.formsDocuments')}
          variant="semiBold"
          dataTestId="benefitsHeader"
        />
      </div>
      <ul className="link-blue">
        {documentLink?.length > 0 &&
          documentLink?.map((item: { link: any; name: any }, index: number) => (
            <DocumentLink
              key={`formsAndDocsLink_${item.name}`}
              link={item.link}
              text={item.name}
              styleClass={''}
              dataTestId={`formsAndDocsLink_${index}`}
            />
          ))}
      </ul>
      {getPhone?.data?.benefitPhone1800 && (
        <div className="pb-4 pt-2 black-color">
          {t('formsDocuments.message.note')}{' '}
          <ContactInfo
            phoneData={{
              phone1800: getPhone?.data?.benefitPhone1800,
              phoneLocal: getPhone?.data?.benefitPhoneLocal,
            }}
          />
        </div>
      )}
    </>
  );
};
export default FormAndDocument;
