import { useTranslation } from 'react-i18next';
import {
  MyConsolidatedResponse,
} from '@sentara/sentara-api-hooks-core';
import {
  CircleProgress,
  Headings,
  PrimaryCircleProgressBar
} from '@sentaraui/optimahealth_web';

interface overviewProps {
  myConsResState: MyConsolidatedResponse | [];
}

function Overview( props:overviewProps ) {
  const  { myConsResState } = props;
  const { t } = useTranslation();
  const deductible = t('yourPlan.label.deductible');

  return (
      <div className="my_plan_overview">
        {myConsResState && myConsResState?.length > 0 ? (
          <>
            <div>
              {myConsResState &&
                myConsResState?.length > 0 &&
                myConsResState?.map((item) => (
                  <div
                    data-testid="my_plan_container"
                    className="my_plan_container"
                    key={item.benefitDescription}
                  >
                    <div>
                      <div
                        data-testid={
                          item.benefitDescription === deductible
                            ? 'deductible_header'
                            : 'moop_header'
                        }
                      >
                        <div className="heading-with-bg-center font-sixteen w-100">
                          <Headings
                            level={2}
                            text={
                              item.benefitDescription === deductible
                                ? t('claimsSummary.label.deductible')
                                : t('claimsSummary.label.inNetworkMoop')
                            }
                            variant="regular"
                          />
                        </div>
                      </div>
                      <div
                        className="text-center"
                        data-testid={
                          item.benefitDescription === deductible
                            ? 'deductible_network'
                            : 'moop_network'
                        }
                      >
                        {item?.networkType}
                      </div>
                      <hr className="m-0" />
                      <div className="row justify-content-center">
                        <div
                          className="col-lg-5 col-md-5 col-sm-12 col-12 mb-4 deductible_progressbar_member"
                          data-testid={
                            item.benefitDescription === deductible
                              ? 'deductible_progressbar_member'
                              : 'moop_progressbar_membr'
                          }
                        >
                          <PrimaryCircleProgressBar
                            className="circle-progress-member"
                            header={item.member?.name}
                            percentage={item.member?.amount}
                            total={item.member?.limit}
                            dataTestId={
                              item.benefitDescription === deductible
                                ? 'deductible_member'
                                : 'moop_member'
                            }
                          />
                        </div>
                        <div
                          className="col-lg-5 col-md-5 col-sm-12 col-12 mb-4 deductible_progressbar_member"
                          data-testid={
                            item.benefitDescription === deductible
                              ? 'deductible_progressbar_family'
                              : 'moop_progressbar_family'
                          }
                        >
                          <CircleProgress
                            className="circle-progress-family"
                            header={item.family?.name}
                            percentage={item.family?.amount}
                            total={item.family?.limit}
                            dataTestId={
                              item.benefitDescription === deductible
                                ? 'deductible_family'
                                : 'moop_family'
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </>
        ) : (
          <p className="fw-400">
            {t('yourPlan.message.moopMsg')}
          </p>
        )}
      </div>
  );
}

export default Overview;
