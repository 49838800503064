import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useMemberIDCard,
  useAuthorizationDepedent,
  useMyDepedentAccess,
  useGetDependentAccess,
} from '@sentara/sentara-api-hooks-core';
import { toSentancePascalCase, getDecryptedValue, UserDetails, errorhandler, internalServerErrorCode, CommonErrorMessage,getCache,Proxy, backToDashboardLink, getLocalStorageBoolean, authorizationsLinkUrl } from 'common';
import AuthorizationTable from './AuthorizationTable';
import { GlobalContext } from 'context';
import {
  Button,
  Headings,
  PrintIcon,
  Label,
  Loader,
  Checkbox,
  Table,
  Paragraph,
  ExternalLinkIcon,
  Link
} from '@sentaraui/optimahealth_web';
/**
 * T authorizations
 * @returns
 */
const Authorizations: React.FC<any> = () => {
  const { t } = useTranslation();
  const [membersTable, setMembersTable] = useState<any>([]);
  const [authorizationTable, setAuthorizationTable] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [dependentAccess, setDependentAccess] = useState(false);
  const [showSpouse, setShowSpouse] = useState(false);
  const [showParent, setShowParent] = useState(false);
  const [memberId, setMemberId] = useState<any>(null);
  const { memberIDCardType } = useMemberIDCard();
  const { MyDepedentAccessType } = useMyDepedentAccess();
  const { GetDependentAccessType } = useGetDependentAccess();
  const { authorizationDepedentType } = useAuthorizationDepedent();
  const [showData, setShowData] = useState(false);
  const {
   loginMID,
   getFeature
  } = useContext(GlobalContext);
  const [errorMessage, showErrorMessage ] = useState('');

  // Function to fetch members data
  async function fetchMembersData() {
    setLoading(true);

    const result = await memberIDCardType('auth');
    if (result?.data?.members) {
      setMembersTable(result?.data.members);
      const response = await GetDependentAccessType(getCache());
      setDependentAccess(response?.data?.isDependentAccess || false);
      fetchDependentDetails();
      if (result?.data?.parentPermission === true) {
        setShowParent(true);
      } else if (result?.data?.spoucePermission === true) {
        setShowSpouse(true);
      }
    } else if (result?.errorCode == internalServerErrorCode) {
      setLoading(false);
      showErrorMessage(t('internalServerError') || '');
    } else if (result?.errorCode) {
      errorhandler(result?.errorCode);
    }
  }

  // Function to fetch dependent details
  async function fetchDependentDetails() {
    setLoading(true);

    let data = {
      ID: memberId,
    };


    const result = await authorizationDepedentType(data,getCache());
    if (result?.data !== null) {
      setAuthorizationTable(result?.data);
    } else if (result?.errorCode) {
      errorhandler(result?.errorCode);
    }
    setShowData(true);
    setLoading(false);
  }
  useEffect(() => {
    (async () => {
      await fetchMembersData();
    })();
  }, []);

  // Effect to fetch dependent details when memberId changes
  useEffect(() => {
    if(!getLocalStorageBoolean(backToDashboardLink)){
      setMemberId(loginMID);
    }
  }, [loginMID]);

   // Effect to fetch dependent details for proxy view
   useEffect(() => {
    (async () => {
  if (membersTable?.length > 0) {
     if(getLocalStorageBoolean(backToDashboardLink)){
      let personalDetails = await getDecryptedValue(Proxy.memberProxy);
      let personalDetailsList = personalDetails ? JSON.parse(personalDetails) : '';
      const decryptedValue = personalDetailsList.MemberId;
      let filteredMid = membersTable.filter((item: {memberId: string}) => item.memberId === decryptedValue);
      const MID = filteredMid[0]?.mId;
      if(MID){
        setMemberId(MID);
      }
    } 
  }
  })();
}, [membersTable]);
  // Function to toggle checkAccess state and check member access
  const updateDependents = (isChecked: boolean) => {
    setDependentAccess(isChecked);
    checkMemberAccess(isChecked);
  };

// Asynchronous function to check member access
  async function checkMemberAccess(value: boolean) {
    setLoading(true);
    const data: string = await getDecryptedValue(UserDetails.usedId);
    // Call MyDepedentAccessType with the provided value and user ID
    await MyDepedentAccessType({
      allowAccess: value,
      userId: data,
    },getCache())
    setLoading(false);
  }

  // Function to get member details
  const getMemberDetails = () => {
    (async () => {
      await fetchDependentDetails();
    })();
  };

  // Function to handle print functionality
  const handlePrint = (e: any) => {
    e.preventDefault();
    window.print();
  };

  const headerData = [ 
    {text:<div className='className="text-center"' data-testid="authSelectoneTab">{t('authorization.label.selectOne')}</div>},
    {text:<span data-testid="authViewClaimTab">{t('authorization.label.viewClaimsfor')}</span>},
    {text:<span  data-testid="authMemberIdTab">{t('authorization.label.memberID')}</span>}
  ]

  const AuthData = (membersTable: any[]) => {
    return(
      membersTable?.length > 0 &&
        membersTable.map((item: any, i: any) => {
          return [
            <tr key={item.mId}>
              <td className="text-center">
                <Checkbox
                  type="radio"
                  id={`'member${i}'`}
                  className="form-check-input mt-0"
                  dataTestId={`'member${i}'`}
                  name={'members'}
                  value={item.mId}
                  checked={memberId === item.mId}
                  onChange={() => {
                    setMemberId(item.mId);
                  }}
                />
              </td>
              <td
                className="fw-700"
                data-testid={`memberName${i}`}
              >
                {toSentancePascalCase(item.memberName)}
              </td>
              <td data-testid={`memberid${i}`}>{item.memberId}</td>
            </tr>,
          ];
        })
    )
  }
  return (
    <>
      {loading &&  <div data-testid="loader"><Loader /></div>}
      <div className="not-print">
        <div className="d-lg-flex d-md-flex not-print align-items-center">
          <div className="mb-4 flex-1">
            <Headings
              level={1}
              text={t('authorization.label.title')}
              dataTestId="authorization_header"
              variant="semiBold"
            />
          </div>
          <div className="d-flex align-items-center gap-2 px-2 mb-4">
            <span data-testid={'print-icon'}>
              <PrintIcon />
            </span>

            <Button
              className="link-text btn-links"
              onClick={handlePrint}
              dataTestId={'print-link'}
            >
              {t('authorization.label.print')}
            </Button>
          </div>
        </div>

        {showSpouse === true && (
          <div className="mb-4">
            <div className="d-flex align-items-center">
              <Checkbox
                id="spouseAllowAccess"
                className="form-check-input mt-0"
                type="checkbox"
                value={dependentAccess}
                dataTestId="spouseAllowAccessCheckbox"
                checked={dependentAccess}
                onChange={(e: any) => updateDependents(e.target.checked)}
              />
              <Label
                className="ps-2 ms-1 font-fourteen"
                dataTestId="spouseAllowAccessLabel"
              >
                {t('authorization.message.spouseAllowAccess')}
              </Label>
            </div>
          </div>
        )}
        {showParent === true && (
          <div className="d-flex align-items-center mb-4">
            <Checkbox
              id="parentAllowAccess"
              className="form-check-input mt-0"
              type="checkbox"
              value={dependentAccess}
              dataTestId="parentAllowAccessCheckbox"
              checked={dependentAccess}
              onChange={(e: any) => updateDependents(e.target.checked)}
            />
            <Label
              className="ps-2 ms-1"
              dataTestId="parentAllowAccessLabel"
            >
              {t('authorization.message.parentAllowAccess')}
            </Label>
          </div>
        )}
        
        {membersTable?.length > 0 && (
        <>
        <Table header={headerData} rowsChildren={AuthData(membersTable)} />
        <div className="flex-display pt-2 mb-4">
          <Button
            dataTestId="viewauthorization"
            onClick={(e: any) => {
              e.stopPropagation();
              getMemberDetails();
            }}
            onKeyDown={(e: any) => {
              e.stopPropagation();
            }}
            variant={'primary_btn'}
          >
            {t('authorization.label.viewauthorization')}
          </Button>
           {getFeature?.ClaimsStaticLink && (
             <div className="d-flex align-items-center ml-3">
                 <Link className="text-decoration-underline" to={authorizationsLinkUrl} dataTestid="claimsStaticLink" target="_blank" rel="noopener noreferrer">{t('authorization.label.authorizationsStaticLink')}<span className="ml-4"><ExternalLinkIcon /></span></Link>
             </div>
             )}
        </div>
        </>
        )}
         
        {/* To show the internalServerError message */}
        <CommonErrorMessage message={errorMessage} />
        
      </div>
      {showData && (
        <AuthorizationTable
          authorizationTable={authorizationTable}
          setAuthorizationTable={setAuthorizationTable}
          memberId={memberId}
        />
      )}
      <div
        className="mb-4 pb-2 let-space-text black-color"
        data-testid="authorizationsProcessed"
      >
        <Paragraph>{t('authorization.label.authorizationsProcessed')} </Paragraph>
      </div>
    </>
  );
};
export default Authorizations;