import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMySSOUrl } from '@sentara/sentara-api-hooks-core';
import { errorhandler } from 'common';
import { GlobalContext } from 'context';
import { Headings, Loader } from '@sentaraui/optimahealth_web';
import TreatmentEstimateCards from './TreatmentEstimateCards';

function TreatmentEstimateCosts() {
   const { getFeature } = useContext(GlobalContext);
  const [loading, setLoading] = useState<any>(false);
  const { t } = useTranslation();
  const { SSOUrlType } = useMySSOUrl();

  const getAPIUrl = async (type: string) => {
    setLoading(true);
    const res = await SSOUrlType(type);
    if (res?.data) {
      window.open(res?.data?.ssoUrl, '_blank');
    } else if (res?.errorCode) {
      errorhandler(res?.errorCode);
    }
    setLoading(false);
  };
  
  return (
    <>
      {loading && <Loader />}
      <div className="mb-4">
        <Headings
          level={1}
          text={t('navigation.label.calculateTreatmentCosts')}
          variant="semiBold"
          dataTestId="estimateTreatmentCostsHeader"
        />
      </div>
      <div className="row estimated_treat_cost_content">
        <TreatmentEstimateCards
            featureEnabled={getFeature?.IBMEstimateTreatmentCosts}
            heading={t('calculateTreatmentCosts.label.totalCost')}
            description={t('calculateTreatmentCosts.label.totalCostDescription')}
            dataTestId='totalCostTreatmentEstimator'
            buttonLabel={t('calculateTreatmentCosts.label.view')}
            getAPIUrl={() => getAPIUrl('CalculateTreatmentCostSSOUrl')}
          />

          <TreatmentEstimateCards
            featureEnabled={getFeature?.HSQEstimateTreatmentCosts}
            heading={t('calculateTreatmentCosts.label.CPT')}
            description={t('calculateTreatmentCosts.label.CPTDescription')}
            dataTestId='cptCodeTreatmentEstimator'
            buttonLabel={t('calculateTreatmentCosts.label.view')}
             getAPIUrl={() => getAPIUrl('HealthSparqOne/CalculateTreatmentCostUrl')}
          />
       
      </div>
    </>
  );
}

export default TreatmentEstimateCosts;
