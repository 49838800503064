import { t } from 'i18next';
import { ProfileDetails, Phone, Address } from 'templates';
import { useEffect, useState, useContext, memo } from 'react';

import {
  SecurityQuestionProps,
  SecurityQuestion,
} from '../../templates/profile/interface';

import {
  userAddressTypeHome,
  getDataByAddressType,
  apiResponseSafeCheck,
  getDecryptedValue,
  UserDetails,
} from 'common';
import ApiWrapper from '../../common/ApiWrapper';
import {
  usePreferenceDetails,
  useSecurityQuestions,
  useSecretQuestionsByUsername,
  useMemberRead,
} from '@sentara/sentara-api-hooks-core';

import { GlobalContext } from 'context';
import { Headings, Loader, Link } from '@sentaraui/optimahealth_web';
import { useForm } from 'react-hook-form';

const Profile = () => {
  const { getFeature, serviceNumber, loginMemberId } =
    useContext(GlobalContext);
  const ciamEnabled = getFeature?.isModernizedAPI;
  const { preferenceDetails } = usePreferenceDetails();
  const { SecurityQuestion } = useSecurityQuestions();
  const { secretQuestion } = useSecretQuestionsByUsername();
  const { fetchMemberDetails } = useMemberRead();
  const [securityQuestion, setSecurityQuestion] = useState<
    SecurityQuestionProps[]
  >([]);
  const [phone, setPhone] = useState<any>({});
  const [changePassword, setChangePassword] = useState<boolean>(false);

  const [state, setState] = useState<any>({});
  const [questionAnswer, setQuestionAnswer] = useState<SecurityQuestion[]>(
    state?.securityQuestions || []
  );
  const [userAddress, setUserAddress] = useState<Address[]>([]);
  const [userPhone, setUserPhone] = useState<Phone[]>([]);

  const [loader, setLoader] = useState(false);
  const {
    data: profileResponse,
    apiError: profileError,
    callApi,
  } = ApiWrapper(preferenceDetails);
  const methods = useForm({});
  const { getValues } = methods;

  useEffect(() => {
    (async () => {
      const response = await SecurityQuestion();
      if (response?.data) {
        setSecurityQuestion(
          response?.data as unknown as SecurityQuestionProps[]
        );
      }
    })();
  }, []);

  useEffect(() => {
    if (apiResponseSafeCheck(serviceNumber)) {
      setPhone(JSON.parse(serviceNumber || '{}'));
    }
  }, [serviceNumber]);

  useEffect(() => {
    if (getFeature?.AccountSettings !== undefined) {
      profileAPI();
    }
  }, [loginMemberId, getFeature?.AccountSettings]);

  const profileAPI = async () => {
    let data = {
      memberId: btoa(loginMemberId),
    };
    setLoader(true);
    callApi(data, '', getFeature?.AccountSettings);
  };

  useEffect(() => {
    if (profileError) {
      setLoader(false);
    } else if (profileResponse || profileResponse === '') {
      setState(profileResponse);
      setLoader(false);
    }
    getValues('question0');
  }, [profileResponse, profileError]);

  useEffect(() => {
    const fetchSecurityQuestions = async () => {
      if (ciamEnabled) {
        const userId = await getDecryptedValue(UserDetails.usedId);
        const response = await secretQuestion(userId);
        if (response?.data) {
          const securityQuestionsResponse: SecurityQuestion[] = (
            response.data as any[]
          ).map((item) => ({
            question: item.secretQuestion,
            questionAnswer: '',
          }));
          setQuestionAnswer(securityQuestionsResponse);
          setState((state: any) => ({
            ...state,
            securityQuestions: securityQuestionsResponse,
          }));
        }
      }
    };
    fetchSecurityQuestions();
  }, [ciamEnabled]);

  useEffect(() => {
    const fetchHomeAddress = async () => {
      if (ciamEnabled) {
        let data = {
          memberId: loginMemberId,
        };
        const response = await fetchMemberDetails(data);
        if (response?.data?.address) {
          const addressResponse = response.data.address;
          const address: Address[] = [
            {
              street: addressResponse?.address1,
              city: addressResponse?.city,
              state: addressResponse?.state,
              zipCode: addressResponse?.zip,
              addressType: userAddressTypeHome,
            },
          ];
          setUserAddress(address);
          setState((state: any) => ({
            ...state,
            address: address,
          }));
        }
      }
    };
    fetchHomeAddress();
  }, [ciamEnabled]);

  useEffect(() => {
    (async () => {
      if (!ciamEnabled && state?.securityQuestions) {
        setQuestionAnswer(state?.securityQuestions);
      }
    })();
  }, [JSON.stringify(state?.securityQuestions)]);

  useEffect(() => {
    // for ciam directly setting setUserAddress after api call
    if (!ciamEnabled) {
      getDataByAddressType(state?.address, userAddressTypeHome, setUserAddress);
    }
    getDataByAddressType(state?.phones, userAddressTypeHome, setUserPhone);
  }, [state?.dependents, ciamEnabled]);

  const childrenstate = (
    <option value={userAddress[0]?.state ?? ''}>
      {userAddress[0]?.state ?? ''}
    </option>
  );

  return (
    <>
      {/* Skip to Main Content link */}
      {loader && <Loader />}
      <Link to="#main-content" className="skip-link">
        {t('accessibility.message.skipToMainContent')}
      </Link>

      <div>
        <main id="main-content">
          <div className="mb-4 pb-2">
            {/* To view the profile heading */}
            <Headings
              level={1}
              text={t('header.label.profile')}
              variant="medium"
              dataTestId="userName"
            />
          </div>
          <ProfileDetails
            state={state} // Represents the profile state.
            setState={setState} // Represents the function to set the profile state.
            getPhone={phone?.data} //Represents the phone data.
            userAddress={userAddress} //Represents the user's address.
            childrenstate={childrenstate} //Represents the state of the user's address.
            userPhone={userPhone} // Represents the user's phone.
            securityQuestion={securityQuestion} //Represents the first security question.
            questionAnswer={questionAnswer} //Represents the security response data.
            setQuestionAnswer={setQuestionAnswer} //Represents the function to set the security.
            changePassword={changePassword} // Represents the password change data.
            setChangePassword={setChangePassword} //Represents the function to set the password change data.
            setUserAddress={setUserAddress} // Represents the function to set the user address change data
          />
        </main>
      </div>
    </>
  );
};

export default memo(Profile);
