import { useState, useContext } from 'react';
import { t } from 'i18next';
import { useSaveEmail } from '@sentara/sentara-api-hooks-core';
import { useForm } from 'react-hook-form';
import { EmailRegex, getMaxLength, errorhandler } from 'common';
import {
  ErrorMessage,
  InputField,
  Label,
  ModalOverlay,
  Checkbox,
  Headings,
  Link,
  Loader,
  Button,
  Paragraph
} from '@sentaraui/optimahealth_web';
import { GlobalContext } from 'context';
import { EmailChangeProps } from './interface';

const EmailChange = ({
  emailId,
  onClickCancel,
  handleSuccess,
  memberView = false,
}: EmailChangeProps) => {
  const { getFeature, proxyMemberId } = useContext(GlobalContext);

  const { saveEmail } = useSaveEmail(); /* save email API call*/
  const [isEmailNotificationOpen, setIsEmailNotificationOpen] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  /* react hook form props call from useForm*/
  const {
    handleSubmit,
    register,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      emailID: emailId,
      confirmEmailID: '',
      termsCondition: false,
    },
  });

  function closePopUpModal() {
    setIsEmailNotificationOpen(false);
  }

  /* save email api call on submit */
  const onSubmit = async () => {
    const email = getValues('emailID');
    if (!isValidEmail(email) || email?.length < 0) {
      setError('emailID', {
        type: 'manual',
        message: t('settings.errors.email') ?? '',
      });
      return;
    } else {
      clearErrors('emailID'); // Clear previous errors
      setLoading(true);
      const emailConsent = {
        email: email,
        optInConsent: true,
      }
      const response = await saveEmail(
        proxyMemberId,
        getFeature?.AccountSettings,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        emailConsent,
      );
      setLoading(false);
      if (response?.errorCode) {
        errorhandler(response?.errorCode);
      } else {
        if (handleSuccess) {
          handleSuccess(email);
        }
      }
    }
  };

  /* check email valid */
  function isValidEmail(email: string) {
    return EmailRegex.test(email);
  }

  const onHandleCheckBox = (event: any) => {
    if (event.target.checked) {
      register('termsCondition', { required: false });
      clearErrors('termsCondition');
    } else {
      register('termsCondition', { required: true });
      setError('termsCondition', { type: 'required' });
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="row">
        <div className={`col-md-12 col-sm-12 col-12 ${memberView ? 'col-lg-12' :  'col-lg-8'}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={``}>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="mb-3 pos-rel">
                    <Label
                      dataTestId={'emailUpdateLabel'}
                      htmlFor="emailInput"
                      children={t('settings.label.emailAddress')}
                      className="pb-1 fw-700"
                    />
                    <InputField
                      maxLength={getMaxLength.email}
                      className={`form-input ${
                        errors?.emailID?.type === 'required'
                          ? 'error-border'
                          : ''
                      }`}
                      placeholder={t('yourPlan.label.emaillblPlaceholder')}
                      data-testid="emailInput"
                      {...register('emailID', {
                        required: true,
                        pattern: {
                          value: EmailRegex,
                          message: 'Password criteria not match',
                        },
                      })}
                      aria-describedby="emailAddress_error"
                    />
                    {errors?.emailID && (
                      <div className="mt-1">
                        <ErrorMessage
                          children={t('activation.errors.emailId')}
                          data-testid={'emailUpdate'}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="mb-3 pos-rel">
                    <Label
                      dataTestId="confirmEmailInputLabel"
                      htmlFor="confirmEmailAddress"
                      children={t('activation.label.confirmEmail')}
                      className="pb-1 fw-700"
                    />
                    <InputField
                      maxLength={getMaxLength.email}
                      className={`form-input ${
                        errors.confirmEmailID ? 'error-border' : ''
                      }`}
                      placeholder={t('activation.label.confirmEmail')}
                      id="confirmEmailAddress"
                      data-testid="confirmEmailInput"
                      {...register('confirmEmailID', {
                        required: true,
                        validate: (value) => {
                          return value.toLowerCase() === getValues('emailID').toLowerCase();
                        },
                      })}
                      aria-describedby="confirmEmail_error"
                    />
                    {errors.confirmEmailID && (
                      <div className="mt-1">
                        <ErrorMessage
                          children={t('activation.errors.confirmEmailError')}
                          data-testid={'confirmEmailInputError'}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <small data-testid="emailDisclaimer">
                  <span className="pe-1">
                    {t('settings.label.pleaseNote')}
                  </span>
                  {t('settings.message.emailDisclaimer')}
                </small>
              </div>
              <div className="d-flex pb-2 mb-3">
                <div className="pos-rel">
                  <div className="d-inline-flex">
                    <Checkbox
                      id="emailNotificationsAndConsentCheck"
                      className={
                        errors.termsCondition
                          ? 'form-check-input mt-0 error-input-bdr'
                          : 'form-check-input mt-0'
                      }
                      type="checkbox"
                      {...register('termsCondition', {
                        required: true,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          onHandleCheckBox(e);
                          setValue('termsCondition', e.target.checked);
                        },
                      })}
                      dataTestId="emailNotificationConsentCheckBox"
                    />
                    <Label
                      dataTestId="confirmEmailInputLabel"
                      htmlFor="emailNotificationsAndConsentCheck"
                      className="pb-1 fw-700"
                    >
                      {t('settings.label.termsOfUsePart')}{' '}
                      <Link
                        dataTestId="emailNotificationConsentTitle"
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          e.preventDefault();
                          setIsEmailNotificationOpen(true);
                        }}
                      >
                        {t(
                          'setMyPreferences.label.emailNotificationsAndConsent'
                        )}
                      </Link>
                      {t('dot')}
                    </Label>
                  </div>
                  {errors.termsCondition && (
                    <ErrorMessage
                      dataTestId={'emailConsent'}
                      children={t('setMyPreferences.error.emailConsent')}
                    ></ErrorMessage>
                  )}
                </div>
              </div>
              <div className="pt-2 button_container">
                <Button
                    dataTestId={'submitButton'}
                    children= {t('settings.label.save')}
                    variant={
                      watch('termsCondition') === true &&
                      watch('emailID') !== '' &&
                      watch('confirmEmailID')
                        ? 'primary_btn btn_block_mob'
                        : 'primary_btn btn_block_mob disabled_btn'
                    }
                    type={'submit'}
                  />
  
                <Button
                  dataTestId={'emailCancelButton'}
                  children={t('settings.label.cancel')}
                  variant={'secondary_btn mw-auto btn_block_mob'}
                  onClick={onClickCancel}
                />
              </div>
            </div>
          </form>
        </div>
        <ModalOverlay
          isOpen={isEmailNotificationOpen}
          onClose={closePopUpModal}
          overlayInfo="Go paperless overlay"
        >
          <Headings
            level={2}
            text={t('setMyPreferences.label.emailNotificationsAndConsent')}
            variant="regular"
            dataTestId="emailNotificationsAndConsentTitle"
          />
          <div
            data-testid="emailNotificationMessage"
            className="phone_notify_msg_wrapper"
          >
            <div className="d-flex flex-column gap-3">
              <Paragraph>
                {t('setMyPreferences.message.emailNotificationMessage1')}
                <br/>
                {t('setMyPreferences.message.emailNotificationMessage2')}
              </Paragraph>
            </div>
          </div>
        </ModalOverlay>
      </div>
    </>
  );
};

export default EmailChange;
