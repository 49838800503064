import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { YearDropdown, HeaderContent, SummaryTable } from 'templates';
import {
  Headings,
  Button,
  Disclaimer,
} from '@sentaraui/optimahealth_web';
import { convertToDate, myPlanUrl, MyPlanTabs, } from 'common';
import { GlobalContext } from 'context';


const ViewTaxSummary = () => {
  const { t } = useTranslation();
  const year: number = new Date().getFullYear();
  const navigate = useNavigate();

  const [memberVlaue, setMemberValue] = useState<any>('');
  const [yearValue, setYearValue] = useState<any>(year);
  const [phoneNumber, setPhoneNumber] = useState<any>({});
  const {
    serviceNumber,
    getFeature,
  } = useContext(GlobalContext);

  useEffect(() => {
    (async () => {
      let phoneNumber = JSON.parse(serviceNumber || '{}');
      setPhoneNumber(phoneNumber);
    })();
  }, []);


  const formattedVal = convertToDate(new Date());

  return (
    <>
      <div className="d-flex" data-testid="taxYearSummaryHeading">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12 col-12">
              <div className="mb-4 flex-1">
                <Headings
                  level={1}
                  text={t('claimsDetails.label.taxYearSummary')}
                  variant="semiBold"
                  dataTestId="authorization_header"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-4">
              <YearDropdown
                setMemberValue={setMemberValue}
                yearValue={yearValue}
                setYearValue={setYearValue}
              />
            </div>
          </div>
        </div>
      </div>

      <HeaderContent
        benefitPhone={phoneNumber?.data?.benefitPhone1800}
        benefitPhoneLocal={phoneNumber?.data?.benefitPhoneLocal}
      />
      <SummaryTable summaryDetails={yearValue && memberVlaue} />
      <div className="mb-3">
        <Disclaimer
          disclaimerContent={`${t(
            'yourPlan.message.disclaimerMsg1'
          )} ${formattedVal}. ${t('yourPlan.message.disclaimerMsg2')}`}
        />
      </div>
      <div className="row">
        <div className="col-lg-auto col-md-auto col-sm12 col-12 mb-4 pb-2">
          <Button
            dataTestId={'viewIndividualClaims'}
            variant={'primary_btn btn_block_mob'}
            type="submit"
            onClick={() => navigate('/home/medical-claims')}
          >{t('claimsDetails.label.viewIndividualClaims')}</Button>
        </div>
        {getFeature?.ViewAnnualDeductiblesandMaximums ? (
          <div className="col-lg-auto col-md-auto col-sm12 col-12 mb-4 pb-2">
            <Button
              dataTestId={'viewAnnualDeductiblesandMaximums'}
              variant={'primary_btn btn_block_mob'}
              onClick={() => navigate(`${myPlanUrl + MyPlanTabs.overViewID}`)}
              type="submit"
            >
              {t('claimsDetails.label.viewAnnualDeductiblesandMaximums')}
            </Button>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ViewTaxSummary;
