import { Headings, Paragraph, Image, Link,ExternalLinkIcon, GlobalWarning } from '@sentaraui/optimahealth_web';
import { useWebMdSSOUrl } from '@sentara/sentara-api-hooks-core';
import { errorhandler, navigateToExternalUrl } from 'common';
import { useTranslation } from 'react-i18next';

interface Program {
    img: string;
    title: string;
    description: string;
    link: string | undefined;
    linkText: string;
}

interface WellnessProgramItemProps {
    program: Program;
    index: number;
}

const WellnessProgramItem: React.FC<WellnessProgramItemProps> = ({ program, index }) => {

    const { t } = useTranslation();
    const { fetchWebMdSSOUrl } = useWebMdSSOUrl();
    const getAPIUrl = async () => {
        const res = await fetchWebMdSSOUrl();
        if (res?.data?.result?.ssoUrl) {
            navigateToExternalUrl(res?.data?.result?.ssoUrl);
        } else if (res?.data?.exceptions?.errorMessage) {
            errorhandler(res.data.exceptions.errorMessage);
        }

    };

    const handleOnItemClick = (index:number) => {
        if (index=== 1) {
            getAPIUrl();
        } else {
            navigateToExternalUrl(program.link)
        }
    }

    return (
        <div className="d-flex">
            <div>
                <Image path={program.img} alt={program.title} />
            </div>
            <div className="ml-3">
                <Headings level={2} text={program.title} variant="semibold"/>
                <Paragraph>{program.description}</Paragraph>
                <div className="mt-3 d-flex align-items-center gap-2">
                    <div>
                        <Link data-testid="program-link" onClick={()=>handleOnItemClick(index)}>{program.linkText}</Link>
                    </div>
                    <div>
                        <ExternalLinkIcon />
                    </div>
                </div>
                <div className="pt-4">
                    {index === 1 && (
                        <GlobalWarning
                            variant="warning"
                            dataTestId="alertShowWarningMessage"
                        >
                            {t('guestWebMD.wellnessPageStaticData.wellnessTools.WarningMessage')}
                        </GlobalWarning>
                    )}
                </div>
            </div>
        </div>
    );
};
export default WellnessProgramItem;