import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  toPascalCase,
  SignOutHandler,
  UserDetails,
  Navigate,
  getDecryptedValue,
  isGuest,
} from 'common';
import { GlobalContext } from 'context';
import { Button, UserIconWhite } from '@sentaraui/optimahealth_web';

// Define the Logout component
const Logout = () => {
  // Initialize translation function
  const { t } = useTranslation();
  const [guestValue, setGuestValue] = useState<boolean>(false);

  // Fetch userLogout function from API hook
  const SignOut = SignOutHandler();
  const { getFeature } = useContext(GlobalContext);

  // Initialize navigation function
  const navigate = useNavigate();

  // State variables to manage user's name
  const [name, setName] = useState('');

  // useEffect hook to update the name state with the user's first name from localStorage
  useEffect(() => {
    // Fetch the user's first name from localStorage or set an empty string if not found
    (async () => {
      const personalInfo = await getDecryptedValue(UserDetails.guest);
      const firstName = JSON.parse(personalInfo || '{}');
      setName(firstName?.user?.firstName || '');
      const guestValue: boolean = await isGuest();
      setGuestValue(guestValue);
    })();
  }, [name]);

  // Convert the user's name to PascalCase
  const capitalizedText = toPascalCase(name);
  const navigateToProfile = () => navigate(Navigate.profile);

  return (
    <div className="d-xl-block d-lg-block d-md-block pos-rel">
      <div className="d-flex align-items-center">
        <div>
          {/* Dropdown button to show user profile information */}
          <Button
            dataTestId="usernameButton"
            id="btnGroupDrop1"
            type="button"
            variant="profile-user-btn btn-w-auto dropdown-toggle d-flex align-items-center dropdownmenu_btn"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="d-flex align-items-center justify-content-center gap-1">
                  <span className="pe-2 d-flex align-items-center">
                    {/* Render the UserIconWhite component */}
                    <UserIconWhite />
                  </span>
                  {/* Render the user's name with a title attribute showing the capitalized version */}
                  <strong
                    className="text-truncate me-2"
                    title={capitalizedText}
                  >
                    {t('header.label.hello')}, {capitalizedText}
                  </strong>
                </span>
                </Button>

          {/* Dropdown menu to show user options */}
          <ul
            className="dropdown-menu dropdown-menu-custom"
            aria-labelledby="btnGroupDrop1"
          >
            {getFeature?.MyProfile && !guestValue && (
              <li>
                {/* Button to navigate to the user's profile page */}
                <Button
                  dataTestId={'profileButton'}
                  variant={'dropdown-item'}
                  onClick={navigateToProfile}
                >
                  {t('header.label.profile')}
                  </Button>
              </li>
            )}
            <li>
              {/* Button to trigger the signOutHandler function */}
              <Button
                dataTestId={'submitButton'}
                variant={'dropdown-item'}
                onClick={SignOut}
              >
                {t('header.label.logout')}
                </Button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

// Export the Logout component as the default export
export default Logout;
