import { useEffect, useState } from 'react';
import { useContactus } from '@sentara/sentara-api-hooks-core';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { RemoveSpaceSlash, CheckIconBig } from 'common';
import {
  Button,
  Label,
  Select,
  TextArea,
  ModalOverlayWithoutClose,
  Headings,
} from '@sentaraui/optimahealth_web';
import { appName } from 'context';

const ContactUs = () => {
  const contactUsBaseURL = `${window.location.href}?success=true`;
  const [searchParams, setSearchParams]: any = useSearchParams();
  const [contactUsResponse, setContactUsResponse] = useState<any>(
    {}
  ); /* Store contact information from API call */
  const { t } = useTranslation();
  const [selectedList, setSelectedList] = useState({
    questionTypeDropdown: '',
    howContactedDropdown: '',
    yourQuestion: '',
  });
  const [isOpen, setIsOpen] =
    useState(false); /** Default open value set to false*/

  const {
    memberId,
    url,
    questionTypes,
    contactOptions,
    orgId,
  } = Object(contactUsResponse);

  const removeSpaceSlash = (textId: string) => {
    return textId
      .trim()
      .replace(RemoveSpaceSlash, '');
  };
  const { ContactusType } = useContactus(); /* API call from contact us api */
  useEffect(() => {
    (async () => {
      const response = await ContactusType(true);
      setContactUsResponse(response?.data);
      const params = searchParams.get('success');
      setIsOpen(params === 'true');
      setSearchParams({});
    })();
  }, []);

  const handleQuestionChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setSelectedList({ ...selectedList, [name]: value });
  };
  const redirectToContact = (e: Event) => {
    e.preventDefault();
    const form = document.getElementById('salesForce') as HTMLFormElement;
    form.submit();
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div>
        <div className="pb-3">
          <Headings
            level={1}
            text={t('footer.label.contactUs')}
            variant="regular"
            dataTestId="contactUsHeadingText"
          />
        </div>
        <form>
          <Label
            className="pb-1 "
            dataTestId="questionTypeDropdownLabel"
            htmlFor="questionTypeDropdown"
            children={t('contactUs.label.questiontype')}
          />
          <span className="text-danger "> *</span>
          <div className="mb-3">
            <Select
              dataTestId="contactusQuestionTypeDropdown"
              className={'form-select'}
              name="questionTypeDropdown"
              onChange={handleQuestionChange}
              defaultValue=""
            >
              <option value="" disabled selected hidden>
                --
              </option>
              {questionTypes?.map((list: string) => (
                <option
                  value={list}
                  key={list}
                  data-testid={removeSpaceSlash(list)}
                >
                  {list}
                </option>
              ))}
              ;
            </Select>
          </div>
          <Label
            className="pb-1 "
            dataTestId="howContactedDropdownLabel"
            htmlFor="howContactedDropdown"
            children={t('contactUs.label.contacted')}
          />
          <span className="text-danger "> *</span>
          <div className="mb-3">
            <Select
              dataTestId="contactusQuestionTypeDropdown"
              className={'form-select'}
              name="howContactedDropdown"
              onChange={handleQuestionChange}
              defaultValue=""
            >
              <option value="" disabled selected hidden>
                --
              </option>
              {contactOptions?.map((list: string) => (
                <option
                  value={list}
                  key={list}
                  data-testid={removeSpaceSlash(list)}
                >
                  {list}
                </option>
              ))}
              ;
            </Select>
          </div>
          <Label
            className="pb-1 "
            dataTestId="planTypeLabel"
            htmlFor="regPlanType"
            children={t('contactUs.label.yourQuestion')}
          />
          <span className="text-danger "> *</span>
          <div className="mb-4 your-question">
            <TextArea
              rows={9}
              onChange={handleQuestionChange}
              className="form-input"
              name="yourQuestion"
            />
          </div>
          <div className="mb-3">
            <Button
              dataTestId={'contactSubmit'}
              variant={'primary_btn btn_block_mob'}
              disabled={
                selectedList.questionTypeDropdown === '' ||
                selectedList.howContactedDropdown === '' ||
                selectedList.yourQuestion === ''
              }
              type="submit"
              onClick={redirectToContact}
            >{t('contactUs.label.submit')}</Button>
          </div>
        </form>
        <ModalOverlayWithoutClose
          isOpen={isOpen}
          overlayInfo={t('contactUs.label.overlayMessage')}
        >
          <div className="mb-3 pb-1 text-center">
            <CheckIconBig />
          </div>
          <div className="mb-3 pb-1">
              <Headings
                level={1}
                text={t('activation.messages.activationSuccess')}
                variant="semiBold"
                dataTestId="successMessage"
              />
          </div>
          <div data-testid="contactusModalTitle">
            <p
              className="mb-4 text-center"
              data-testid="contactusModalDescription"
            >
              {t('contactUs.label.successMessage')}
            </p>
            <div className="text-center">
              <Button
                dataTestId={'contactSubmit'}
                variant={'primary_btn btn_block_mob'}
                onClick={closeModal}
              >{t('contactUs.label.ok')}</Button>
            </div>
          </div>
        </ModalOverlayWithoutClose>
        <p data-testid="contactusFooterNote">
            {t('contactUs.label.note')}
        </p>
      </div>
      <form action={url} method="post" id="salesForce">
        <input type="hidden" name="orgid" value={orgId} />
        <input type="hidden" name="orgin" id="origin" value="Web" />
        <input type='hidden' name='appName' value={appName} />
        <input
          type="hidden"
          id={contactUsResponse?.memberIdKey}
          name={contactUsResponse?.memberIdKey}
          value={memberId ? atob(memberId) : ''}
        />
        <input type="hidden" name="retURL" value={contactUsBaseURL} />
        <input
          type="hidden"
          name={contactUsResponse?.questionTypeKey}
          id={contactUsResponse?.questionTypeKey}
          value={selectedList.questionTypeDropdown}
        />
        <input
          type="hidden"
          name={contactUsResponse?.contactOptionKey}
          id={contactUsResponse?.contactOptionKey}
          value={selectedList.howContactedDropdown}
        />
        <input
          type="hidden"
          name="email"
          id="email"
          value={contactUsResponse?.emailId ? atob(contactUsResponse?.emailId) : ''}
        />
        <input
          type="hidden"
          name="description"
          value={selectedList?.yourQuestion}
        />
        <input type="hidden" value="submit" />
      </form>
    </>
  );
};

export default ContactUs;