export const Navigate = {
  dashboard: '/dashboard',
  profile:'/profile',
  memberProfile:'/home/member-profile',
  enrollBenefits: '/home/enroll-benefits',
  medicalClaimsURL: '/home/medical-claims',
  registration: '/account/activation',
  homeContactUs: '/home/contact-us',
  wellnessProgram: '/home/wellness-programs',
};
