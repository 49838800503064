
import { useTranslation } from 'react-i18next';
import { RightArrowIcon} from 'common';
import { Headings, Button, Paragraph } from '@sentaraui/optimahealth_web';


  //  Initialize useTranslation hook
 

  interface CommonClaimsProps {
    staticUrlLink: string;
    claimTitle: string;
    claimMsg: string;
  }

  const CommonClaims: React.FC<CommonClaimsProps> = ({ staticUrlLink, claimTitle, claimMsg }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="mb-4">
        <Headings
          level={1}
          text={claimTitle}
          variant="medium"
          dataTestId="pharmacyHeader"
        />
      </div>
      <div className="pharmacy_claims_wrapper mb-4">
      <Paragraph
          className="fw-500"
          data-testid="pharmacyClaimsText"
        >{claimMsg}</Paragraph>
        <div className="d-flex align-items-center">
        <Button
            dataTestId={'continueNavigate'}
            onClick={() => {
              window.open(staticUrlLink, '_blank'); // Open SSO URL in a new tab
            }}
            variant="link-text bgColor-snow btn-links"
          >{t('activation.label.continue')}</Button>
          <span className='green-color-stroke' aria-hidden="true">
            <RightArrowIcon />
          </span>
        </div>
      </div>
    </>
  );
};

export default CommonClaims;
