import React, { createContext, useMemo, useState } from 'react';
import FeatureToggleProvider from './FeatureToggleProvider';
export const GlobalContext = createContext({} as any);
const GlobalProvider = ({ children }: any) => {
  const [userEncodedData, setUserEncodedData] = useState([]);
  const [getFeature, setFeature] = useState({});
  const [zipValue, setZipValue] = useState('');
  const [addressValue, setAddressValue] = useState('');
  const [proxyDataValue, setProxyDataValue] = useState('');
  const [proxyData, setProxyData] = useState([]);
  const [age, setAge] = useState('');
  const [enableView, setEnableView] = useState(false);
  const [proxySelected, setProxySelected] = useState([]);
  const [proxyMemberId, setProxyMemberId] = useState('');
  const [proxyDropDown, setProxyDropDown] = useState(false);
  const [enableDelete, setEnableDelete] = useState(false);
  const [updateProxy, setUpdateProxy] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [loginMID, setLoginMID] = useState('');
  const [loginProxyId, setLoginProxyId] = useState('');
  const [loginMemberId, setLoginMemberId] = useState('');
  const [getAuthToken, setAuthToken] = useState('');
  const [proxyUniqueId, setProxyUniqueId] = useState('');

  const [xauthToken,setXauthToken] = useState('');
  const [xsentaraTokenID,setXsentaraTokenID] = useState('');


  const [isDesignatedRepresentative, setIsDesignatedRepresentative] =
    useState(false);
  const [designatedDropdownLink, setDesignatedDropdownLink] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [confirmProxy, setConfirmProxy] = useState([]);
  const [memberProxyData, setMemberProxyData] = useState([]);
  const [proxyUpdate, setProxyUpdate] = useState(false);
  const [isGuestSwitch, setIsGuestSwitch] = useState(false);
  const [isBacktoDashBoard, setIsBacktoDashBoard] = useState(false);
  const [primaryUser, setPrimaryUser] = useState('');
  const [planName, setPlanName] = useState<string>('');
  const [planWellness, setPlanWellness] = useState<string>('');  
  const [serviceNumber, setServiceNumber] = useState<string>("");
  const [isMemberTabDefault, setIsMemberTabDefault] = useState<boolean>(false);
  const [ssoLink, setSSOLink] = useState<string>("");
  const [isDesignatedTabShow, setIsDesignatedTabShow] = useState<boolean>(false);

  //This Function will be called on signout to clear all context
    const clearContextValues = ()=> {
    setUserEncodedData([]);
    setFeature({});
    setZipValue("");
    setAddressValue("");
    setProxyDataValue("");
    setProxyData([]);
    setAge('');
    setEnableView(false);
    setProxySelected([]);
    setProxyDropDown(false);
    setEnableDelete(false);
    setUpdateProxy(false);
    setShowContent(false);
    setLoginMID('');
    setLoginProxyId('');
    setLoginMemberId('');
    setIsDesignatedRepresentative(false);
    setDesignatedDropdownLink(false);
    setViewModal(false);
    setConfirmProxy([]);
    setMemberProxyData([]);
    setProxyUpdate(false);
    setIsGuestSwitch(false);
    setIsBacktoDashBoard(false);
    setPrimaryUser('');
    setPlanName('');
    setPlanWellness('');
    setServiceNumber('');
    setIsMemberTabDefault(false);
    setSSOLink('');
    setIsDesignatedTabShow(false);
    setProxyUniqueId('');
  };


  const globalContextVal = useMemo(
    () => ({
      userEncodedData,
      setUserEncodedData,
      getFeature,
      setFeature,
      setZipValue,
      zipValue,
      setAddressValue,
      addressValue,
      setProxyDataValue,
      proxyDataValue,
      loginMemberId,
      setLoginMemberId,
      setProxyData,
      proxyData,
      age,
      setAge,
      proxySelected,
      setProxySelected,
      setEnableView,
      enableView,
      proxyDropDown,
      setProxyDropDown,
      enableDelete,
      setEnableDelete,
      updateProxy,
      setUpdateProxy,
      setShowContent,
      showContent,
      isDesignatedRepresentative,
      setIsDesignatedRepresentative,
      designatedDropdownLink,
      setDesignatedDropdownLink,
      memberProxyData,
      setMemberProxyData,
      viewModal,
      setViewModal,
      setProxyUpdate,
      proxyUpdate,
      setConfirmProxy,
      confirmProxy,
      isGuestSwitch,
      setIsGuestSwitch,
      isBacktoDashBoard,
      setIsBacktoDashBoard,
      primaryUser, 
      setPrimaryUser,
      planName,
      setPlanName,
      planWellness,
      setPlanWellness,
      loginMID,
      setLoginMID,
      loginProxyId,
      setLoginProxyId,
      serviceNumber,
      setServiceNumber,
      setAuthToken,
      getAuthToken,
      setProxyMemberId,
      proxyMemberId,
      isMemberTabDefault,
      setIsMemberTabDefault,
      setSSOLink,
      ssoLink,
      isDesignatedTabShow,
      setIsDesignatedTabShow,
      clearContextValues,
      setProxyUniqueId,
      proxyUniqueId,
      xauthToken,
      setXauthToken,
      xsentaraTokenID,
      setXsentaraTokenID
    }),
    [
      addressValue,
      age,
      confirmProxy,
      designatedDropdownLink,
      enableDelete,
      enableView,
      getFeature,
      isDesignatedRepresentative,
      loginMemberId,
      memberProxyData,
      proxyData,
      proxyDataValue,
      proxyDropDown,
      proxySelected,
      proxyUpdate,
      showContent,
      updateProxy,
      userEncodedData,
      viewModal,
      zipValue,
      isGuestSwitch,
      setIsBacktoDashBoard,
      isBacktoDashBoard,
      primaryUser, 
      setPrimaryUser,
      planName,
      setPlanName,
      planWellness,
      setPlanWellness,
      loginMID,
      setLoginMID,
      loginProxyId,
      setLoginProxyId,
      serviceNumber,
      setServiceNumber,
      setAuthToken,
      getAuthToken,
      setProxyMemberId,
      proxyMemberId,
      isMemberTabDefault,
      setIsMemberTabDefault,
      setSSOLink,
      ssoLink,
      isDesignatedTabShow,
      setIsDesignatedTabShow,
      clearContextValues,
      setProxyUniqueId,
      proxyUniqueId,
      xauthToken,
      setXauthToken,
      xsentaraTokenID,
      setXsentaraTokenID
    ]
  );
  return (
    <GlobalContext.Provider value={globalContextVal} >
      {/* Wrap children with FeatureToggleProvider context provider */}
      <FeatureToggleProvider>{children}</FeatureToggleProvider>
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;