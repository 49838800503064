import { useTranslation } from 'react-i18next';
import {
    Button,
    ModalOverlay,
    Link
} from '@sentaraui/optimahealth_web';

import { urlRegex } from 'common';

export interface MemberDetailsProps {
    memberErrorModal: boolean,
    memberErrorMessage:string,
    closeMemberErrorModal: ()=>void,
}

const MemberInfoModal = (MemberDetailsData: MemberDetailsProps) => {
    const { t } = useTranslation(); 
    const {memberErrorModal,memberErrorMessage,closeMemberErrorModal} = MemberDetailsData;

    const urlSplit = (text: string) => {
        let url = text.match(urlRegex);
        let textMessageArray = url?.length ? text.split(url[0]) : [];
        if (url?.length && textMessageArray.length>1) {
            return (
                <>
                    <div className='d-inline-flex me-1'>{textMessageArray[0]}</div>
                    <Link
                        to={url[0]}
                        target="_blank"
                        rel="noreferrer"
                        className="text-decoration-underline"
                        data-testid="memberLink"
                    >
                        {url[0]}
                    </Link>
                    <div className='d-inline-flex ms-1'>{textMessageArray[1]}
                    </div>
                </>
            );
        }
        else {
            return null;
        }
    }
    return (
            <div className="modal_popup_member">
                <ModalOverlay
                    isOpen={memberErrorModal}
                    onClose={closeMemberErrorModal}
                    data-userid="memberInfoModal"
                    contentLabel="MemberInfo Overlay"
                >
                    <div data-testid="member-modal-content">
                        <div className="mt-4 pt-2" data-testid="member-modal-info">
                            {urlSplit(memberErrorMessage)}
                        </div>
                        <div className="d-flex flex-column flex-lg-row flex-md-row  align-items-center justify-content-center mt-3 gap-3">
                            <Button
                                dataTestId="okButton"
                                className="primary_btn btn_block_mob"
                                onClick={closeMemberErrorModal}
                            >
                                {t('activation.label.buttonOk')}
                            </Button>
                        </div>
                    </div>
                </ModalOverlay>
            </div>
    );
};
export default MemberInfoModal;
